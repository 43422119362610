/* .product-details-section-align {
    margin-top: 50px;
    margin-bottom: 50px;
} */

.product-details-left{
    position: -webkit-sticky;
    position: sticky;
    top: calc(var(--header-offset, 90px) + 3em);
    /* top: 0; */
    background: var(--whiteColor);
    border-radius: 10px;
    margin-right: 6px;
}

/* .product-details-img-outer{
    display: flex;
    flex-wrap: wrap;
} */
.product-details-img{
    display: inline-block;
    width: 100%;
}
.product-details-img .img-thumbnail{
    background: none;
    padding: 0%;
    border: 0;
    border-radius: 10px; 
    width: 100%;
    height: 400px;
    object-fit: contain;
    background: var(--body-bg);
}

.product-details-info-outer{
    margin: 0;
}

.product-details-title{
    font-size: 18px;
    font-weight: 500;
    margin-top: 0rem;
    line-height: 1.5;
}
.product-details-box-title{
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 15px;
}
.product-details-box-title span{
    font-weight: 500;
}

.product-details-info-box{
    border-bottom: 1px solid var(--borderColor);
    padding-bottom: 18px;
    margin-bottom: 18px; 
}

.product-details-info-box:last-child{
    border-bottom: none;
}

.product-details-price span{
    font-size: 24px;
    font-weight: 500;
}
.product-details-price span.cut{
    text-decoration: line-through;
    font-size: 16px;
    font-weight: 400;
}
.product-details-price span.product-details-discount{
    font-size: 18px;
    font-weight: 400;
    color: var(--primary);
}
.product-details-status{
    font-size: 16px;
    color: var(--lightGreen);
}
.product-details-cb-badge{
    background: var(--badge);
    padding: 2px 7px;
    color: var(--blackColor);
    font-size: 12px !important;
    font-weight: 500;
    border-radius: 5px;
  }
/* choose size start */
.product-details-choose-size label.radio{
    cursor: pointer;
    margin-bottom: 0;
}
.product-details-choose-size label.radio input{
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
    pointer-events: none;
}
.product-details-choose-size label.radio span{
    padding: 1px 9px;
    margin-right: 12px;
    background-color: var(--borderColor);
    display: inline-block;
    color: var(--darkText);
    border-radius: 6px;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
}
.product-details-choose-size label.radio input:checked + span{
    background-color: var(--primary);
    color: var(--whiteColor);
}
/* choose size end */


/* choose color start */
.product-details-choose-color { 
    width: 100%;
} 
.product-details-choose-color-allColors {
    width: 100%; 
    /* display: inline-block; */
    vertical-align: middle;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
}
/* .product-details-choose-color .radio_button {
    position: absolute;
    opacity: 0;
}
.product-details-choose-color-radio {
    display: inline-block;
    width: 21px;
    height: 21px;
    border-radius: 50%; 
    background-color: var(--whiteColor);
    cursor: pointer;
    vertical-align: middle;
}
.product-details-choose-color .radio_button:checked + .product-details-choose-color-radio {
    width: 26px;
    height: 26px; 
    border: 4px solid var(--radiostrock);
}
.product-details-choose-color-black {
    background-color: var(--blackColor);
}
.product-details-choose-color-brown {
    background-color: var(--browncolor);
} 
.product-details-choose-color-green {
    background-color: var(--lightGreen);
} 
.product-details-choose-color-gold {
    background-color: var(--goldcolor);
} */



.product-details-choose-color label.radio{
    cursor: pointer;
    margin-bottom: 0;
}
.product-details-choose-color label.radio input{
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
    pointer-events: none;
}
.product-details-choose-color label.radio span{
    padding: 1px 10px;
    /* margin-right: 12px; */
    background-color: var(--borderColor);
    display: inline-block;
    color: var(--darkText);
    border-radius: 6px;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
}
.product-details-choose-color label.radio input:checked + span{
    background-color: var(--primary);
    color: var(--whiteColor);
}

/* choose color end */


/* choose quantity start */

.product-details-choose-quantity{
    /* display: flex; */
    display: inline-flex;
}
.product-details-choose-quantity .value-button {
    display: inline-block;
    border: 1px solid #ddd;
    margin: 0px;
    width: 40px;
    /* height: 20px; */
    text-align: center;
    vertical-align: middle;
    padding: 7px 0;
    /* background: #eee; */
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  .product-details-choose-quantity .value-button:hover {
    cursor: pointer;
  }
  
  .product-details-choose-quantity .decrease-sign {
    margin-right: 0px;
    border-radius: 4px 0 0 4px;
  }
  
  .product-details-choose-quantity .increase-sign {
    margin-left: -3px;
    border-radius: 0 4px 4px 0;
  }
  
  .product-details-choose-quantity .quantity-number {
    text-align: center;
    border: none;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    margin: 0px;
    padding-top: 3px;
    width: 55px;
    /* height: 42px; */
    font-size: 13px;
    font-weight: 500;
    background: none;
    color: var(--darkText);
  }
  .product-details-choose-quantity .quantity-number:focus {
    outline: 0;
  }
  
  .product-details-choose-quantity input[type=number]::-webkit-inner-spin-button,
  .product-details-choose-quantity input[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
  }

  .product-details-share{
    display: inline-block;
    /* float: right; */
  }
  .product-details-share button{
    background: none;
    border: 1px solid var(--lightGray) !important;
    font-size: 19px;
    padding: 8px 10px 6px;
  }
  .product-details-wishlist{
    display: inline-block;
    /* float: right; */
  }
  .product-details-wishlist button{
    background: none;
    border: 1px solid var(--primary);
    font-size: 22px;
    padding: 10px 10px 5px;
  }
/* choose quantity end */

.product-details-btn{
    display: flex;
    flex-wrap: nowrap;
}
.product-details-btn button{
    /* width: 50%; */
    width: 154px;
    padding: 9px 26px;
    font-size: 16px;
    font-weight: 500;
    border-radius: 8px;
}
.product-details-store-info{ 
    font-size: 16px;
}
.product-details-store-info span{ 
    color: var(--primary);
}
.product-details-pincode{
    color: var(--textGray);
    font-size: 14px;
}
.product-details-pincode .input-group input{
    font-size: 16px;
    border-right:none;
    padding: 25px 15px;
    border-radius: 8px;
    border-color: var(--borderColor);
}
.product-details-pincode .input-group button{
    font-size: 16px;
    background: none; 
    border-left:none;
    border-color: var(--borderColor);
    border-radius: 8px;
    color: var(--primary);
    text-decoration: underline;
}
.product-details-pincode .input-group button{
    outline: 0;
}
.product-details-description{
    /* margin-left: 15px; */
    margin-bottom: 0;
    font-size: 14px;
}
.product-details-description p{
    margin-bottom: 0;
}
.product-details-description ul{
    margin-bottom: 0;
}
.product-details-description ul li{
    /* margin-bottom: 20px; */
    margin-left: 15px;
    line-height: 24px;
}
.product-details-description li:last-child{
    margin-bottom: 0px;
}



/* product details image img-thumbnail new layout style start */

.product-images{
    margin-top: 7px;
    display: flex;
    gap: 13px;
}

.product-images .product-right-img{
    height: 490px;
    width: 100%;

}
.product-images .product-right-img img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.product-images .product-left-img-box{
    display: flex;
    flex-direction: column;
    gap: 13px;
}
.product-images .product-left-img-box .product-left-img{
    height: 80px;
    width: 80px;

}
.product-images .product-left-img-box .product-left-img img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 6px;
}

/* product details image img-thumbnail new layout style end */



@media only screen and (min-width: 320px) and (max-width: 413px){
    .product-details-info-outer {
        margin-top: 23px;
    }
    .product-details-info-box:last-child { 
        margin-bottom: 0;
        padding-bottom: 0;
    }
    .product-details-title {
        font-size: 14px; 
    }
    .product-details-price span {
        font-size: 18px; 
    }
    .product-details-price span.cut { 
        font-size: 14px; 
    }
    .product-details-price span.product-details-discount {
        font-size: 16px; 
    }
    .product-details-status {
        font-size: 14px; 
    }
    .product-details-box-title {
        font-size: 14px; 
    }
    .product-details-share button {
        padding: 7px 8px 7px;
    }
    .product-details-wishlist button {
        padding: 10px 9px 5px;
    }
    .product-details-btn button {
        width: 50%;
        font-size: 14px;
        padding: 10px 16px;
    }
    .product-details-store-info {
        font-size: 14px;
    }
    .product-details-pincode { 
        font-size: 12px;
    }
    .product-details-pincode .input-group input {
        font-size: 14px; 
    }
    .product-details-pincode .input-group button {
        font-size: 14px; 
    }
    .product-details-description { 
        font-size: 12px;
    }

}


@media only screen and (min-width: 414px) and (max-width: 768px){

    /* .shopping-catagory-nav-outer .react-multi-carousel-item{
        width: 96px !important;
    } */



    .product-details-info-outer {
        margin-top: 23px;
    }
    .product-details-info-box:last-child { 
        margin-bottom: 0;
        padding-bottom: 0;
    }
    .product-details-title {
        font-size: 16px; 
    }
    .product-details-price span {
        font-size: 18px; 
    }
    .product-details-price span.cut { 
        font-size: 14px; 
    }
    .product-details-price span.product-details-discount {
        font-size: 16px; 
    }
    .product-details-status {
        font-size: 14px; 
    }
    .product-details-box-title {
        font-size: 14px; 
    }
    .product-details-share button {
        padding: 7px 8px 7px;
    }
    .product-details-wishlist button {
        padding: 10px 9px 5px;
    }
    .product-details-btn button {
        width: 50%;
        font-size: 14px; 
    }
    .product-details-store-info {
        font-size: 14px;
    }
    .product-details-pincode { 
        font-size: 12px;
    }
    .product-details-pincode .input-group input {
        font-size: 14px; 
    }
    .product-details-pincode .input-group button {
        font-size: 14px; 
    }
    .product-details-description { 
        font-size: 12px;
    }

}

@media only screen and (min-width: 540px) and (max-width: 768px){
    .product-details-btn button {
        width: 150px;
        padding: 8px 14px;
        font-size: 16px;
    }

}


@media only screen and (min-width: 768px) and (max-width: 992px){
    .product-details-info-outer {
        margin-top: 23px;
    }
    .product-details-btn button {
        width: 150px;
        padding: 10px 14px;
        font-size: 16px;
    }

}


@media only screen and (min-width: 1024px) and (max-width: 1280px){
    .product-details-left{
        top: calc(var(--header-offset, 90px) + 5em);
    }

}


@media only screen and (min-width: 1280px) and (max-width: 1440px){


}