
.digital-gold-section-head{
  text-align: center;
}
.digital-gold-section-head .section-head-title{
  font-size: 32px;
  font-weight: 500;
  color: var(--secondary);
  padding: 0px 0 25px 0;
}
.digital-gold-section-head .section-head-title span{
  color: var(--gold);
}

.digigold-logintext{
  text-align: center;
  margin-bottom: 25px;
  padding: 14px 50px 20px;
  border: 1px solid var(--secondary);
  border-radius: 10px;
  /* background: var(--whiteColor); */
}
.digigold-logintext-title{
  font-size: 30px;
  font-weight: 500;
  color: var(--secondary);
  /* margin: 0; */
}
.digigold-logintext-btn{
  /* font-size: 24px;
  font-weight: 500;
  color: var(--secondary);
  margin: 0; */
  padding: 10px 60px;
  border-radius: 5px;
}

.my-vault-wrapper{
  margin-bottom: 25px;
  /* margin-top: 28px; */
}
.my-vault-badge-wrapper{
  position: absolute;
  top: -17px;
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
}
.my-vault-badge{
  background: var(--primary);
  padding: 5px 36px;
  text-align: center;
  color: var(--whiteColor);
  font-size: 20px;
  font-weight: 500;
  border-radius: 6px;
}
.my-vault-inner{
  border: 1px solid var(--secondary);
  border-radius: 10px;
  background: var(--whiteColor);
  padding: 35px 50px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.vault-value{
  display: inline-block;
  /* padding: 30px; */
}
.vault-value-text{
  font-size: 28px;
  font-weight: 500;
  color: var(--darkText);
  margin: 0;
}
.vault-value-count{
  font-size: 24px;
  font-weight: 500;
  color: var(--secondary);
  margin: 0;
}
.vertical-separator{
  border-left: 1px solid var(--darkText);
  height: 60px;
}


.buy-sell-form-outer{
  border: 1px solid var(--secondary);
  border-bottom: 0;
  background: var(--whiteColor);
  padding: 20px;
  border-radius: 16px 16px 0px 0px;
  width: 90%;
  margin: auto;
  background-image: url(../../../../public/images/digigold-images/golden-border.svg);
  background-repeat: no-repeat;
  background-position: bottom;
  /* background-size: 100%; */
}
.current-rate-outer{
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 30px;
}
.current-rate{
  text-align: center;
  font-weight: 600;
  color: var(--secondary);
}
.current-rate .current-rate-title{
  display: block;
  font-size: 30px;
}
.current-rate .current-rate-amt{
  display: block;
  font-size: 24px;
}
.buy-sell-option{
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 20%;
  margin: 35px auto;
}
.buy-sell-option h2{
  font-size: 30px;
  font-weight: 500;
  color: var(--darkText);
  padding: 0 20px;
}
.buy-sell-option h2:hover{
  text-decoration: none;
  color: var(--darkText);
}
.buy-sell-option .option-active{
  border-bottom: 3px solid var(--darkText);
}


.buy-sell-tab-inner{
  width: 90%;
  margin: auto;
}
.tab-pills-wrapper{
  border: 1px solid var(--lightGray);
  padding: 2px !important;
  border-radius: 5px;
}
.tab-pills-wrapper .tab-pills{
  width: 50%;
}
.nav-pills .nav-link.active, .nav-pills .show>.nav-link{
  background-color: var(--secondary) !important;
}
.tab-pills .tab-pills-link{
  text-align: center;
  padding: 0.8rem;
  font-size: 16px;
  font-weight: 500;
  color: var(--primary);
}
.buy-sell-tab-inner .exchange-arrow-outer{
  width: 10%;
}
.buy-sell-tab-inner .exchange-arrow img{
  width: 24px;
}

/* price check form start */
.input-wrapper{
  width: 45%;
}
.buy-sell-tab-inner .input {
  position: relative;
  font-size: 16px;
  background-color:inherit;
}

.buy-sell-tab-inner .input input {
  line-height:1;
  font-size: 16px;
  min-width: 100%;
  padding: 0 16px;
  height: 50px;
  border-radius: 4px;
  border: 1px solid var(--lightGray);
  /* box-shadow: 0 0 0 1px #a0aec0; */
  background:transparent;
  color:var(--darkText);
}
.buy-sell-tab-inner .input input::-webkit-input-placeholder  {
  opacity: 0.6;
  transition: opacity 200ms;
  color: var(--darkText);
}
.buy-sell-tab-inner .input input::-moz-placeholder {
  opacity: 0.6;
  transition: opacity 200ms;
  color: var(--darkText);
}
.buy-sell-tab-inner .input label {
  color: var(--textGray);
  position: absolute;
  top: 13px;
  left: 16px;
  transition: all 200ms;
}

.buy-sell-tab-inner .input input:focus {
  outline: none;
  /* box-shadow: 0 0 0 1px #63b3ed; */
}
.buy-sell-tab-inner .input input:focus::-webkit-input-placeholder {
  opacity: 0;
}
.buy-sell-tab-inner .input input:focus::-moz-placeholder {
  opacity: 0;
}
.buy-sell-tab-inner .input input:focus ~ label,
.buy-sell-tab-inner .input input:not(:placeholder-shown) ~ label {
  background-color: var(--whiteColor);
  padding: 1px 5px;
  border-radius: 3px;
  top: 7px;
  left: 0;
  transform: translate3d(0,-22px,0) scale(0.75);
  color: var(--primary);
}
.buy-sell-tab-inner .input input:focus ~ label {
  color: var(--primary);
}

.buy-sell-tab-inner .input input:valid:not(:placeholder-shown) {
  
  border: 1px solid var(--primary);
}
.buy-sell-tab-inner .input input:valid:not(:placeholder-shown) ~ label {
  color: var(--primary) !important;
  font-size: 17px;
}

.buy-sell-tab-inner .input input:invalid:not(:placeholder-shown) {
  
  border: 1px solid var(--primary);
}
.buy-sell-tab-inner .input input:invalid:not(:placeholder-shown) ~ label {
  color: var(--darkText) !important;
}



.buy-sell-tab-inner .input input:focus{
  border-color: var(--primary);
}
.buy-sell-tab-inner .input input:hover{
  border-color: var(--primary);
}


/* price check form end */


.sip-box-wrapper{
  margin-top: 15px;
  margin-left: -15px;
  margin-right: -15px;
}
.sip-box-inner{
  display: flex;
  justify-content: space-between;
}
.sip-box-inner .sip-amt{
  background: var(--addamtbtn);
  border: none;
  padding: 11px 24px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 5px;
  min-width: 115px;
}
.sip-box-inner .monthly-sip{
  background: var(--secondary);
  color: var(--whiteColor);
  border: none;
  padding: 13px 24px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 5px;
  min-width: 245px;
}
.buy-btn{
  margin-top: 40px;
  margin-bottom: 30px;
  text-align: center;
}
.quick-buy{
  padding: 10px 60px;
  border-radius: 5px;
}


/* digital gold services start */
.digigold-service-box-outer{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.digigold-service-box-inner{
  width: 20%;
}
.digigold-service-div-outer {
  text-decoration: none;
  color: var(--blackColor);
}
.digigold-service-div-outer:hover {
  text-decoration: none;
  color: var(--blackColor);
}
.digigold-service-div-box {
  padding: 18px;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  margin: 0;
  /* background: var(--whiteColor);  */
  transition: all 0.3s ease-in-out;
  text-align: center;
}

.digigold-service-div-box:hover {
  transform: scale(1.1);
}

.digigold-service-div-box .digigold-service-icon {
  margin: 0 auto 15px auto;
  padding-top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 0.3px solid var(--borderColor);
  border-radius: 50%;
  width: 70px;
  height: 70px;
  background: var(--whiteColor); 
  -webkit-box-shadow: 0px 1px 3px 1px rgb(0 0 0 / 7%);
  -moz-box-shadow: 0px 1px 3px 1px rgb(0 0 0 / 7%);
  box-shadow: 0px 1px 3px 1px rgb(0 0 0 / 7%);
}

.digigold-service-div-box .digigold-service-icon .digigold-service-img {
  width: 38px;
  line-height: 1;
}

.digigold-service-div-box .digigold-service-title { 
  margin-bottom: 0px; 
}

.digigold-service-div-box .digigold-service-title h3 {
  color: var(--secondary);
  font-size: 18px;
  font-weight: 500;
}

/* digital gold services end */




/* digital gold how it work start */
.digigold-work-section-head{
  text-align: center;
}
.digigold-work-section-head .section-head-title{
  font-size: 32px;
  font-weight: 500;
  color: var(--secondary);
  padding: 0px 0 25px 0;
}
.digigold-work-box-outer{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.digigold-work-box-inner{
  width: 20%;
}
.digigold-work-div-outer {
  text-decoration: none;
  color: var(--blackColor);
}
.digigold-work-div-outer:hover {
  text-decoration: none;
  color: var(--blackColor);
}
.digigold-work-div-box {
  padding: 18px;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  margin: 0 15px;
  /* background: var(--whiteColor);  */
  transition: all 0.3s ease-in-out;
  text-align: center;
}

.digigold-work-div-box:hover {
  transform: scale(1.1);
}

.digigold-work-div-box .digigold-work-icon {
  margin: 0 auto 15px auto;
  padding-top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 70px;
  height: 70px;
}

.digigold-work-div-box .digigold-work-icon .digigold-work-img {
  width: 38px;
  line-height: 1;
}

.digigold-work-div-box .digigold-work-title { 
  margin-bottom: 15px;
  height: auto;
}

.digigold-work-div-box .digigold-work-title h3 {
  color: var(--darkText);
  font-size: 18px;
  font-weight: 500;
}

.digigold-work-div-box .digigold-work-description {
  font-size: 14px; 
  margin-bottom: 0;
  text-align: center;
}
/* digital gold how it work end */


/* digital gold order summery page end */
.digigold-insert-value{
  text-align: center;
  margin-top: 16px;
  padding-bottom: 5px;
  border-bottom: 1px solid var(--borderColor);
}
.digigold-insert-title{
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 35px;
}
.digigold-insert-title span{
  color: var(--primary);
}
.digigold-insert-darktext{
  font-size: 16px;
  font-weight: 500;
  color: var(--secondary);
  margin-bottom: 10px;
}
.digigold-insert-amt{
  font-size: 16px;
  font-weight: 500;
}
.digigold-payble-value{
  text-align: center;
  margin-top: 18px;
  margin-bottom: 25px;
  padding-bottom: 5px;
  border-bottom: 1px solid var(--borderColor);
}
.digigold-payble-darktest{
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;
}
.digigold-payble-amt{
  font-size: 20px;
  font-weight: 600;
  color: var(--primary);
}


/* radio button start */
.digigold-payment-title{
  font-size: 18px;
  font-weight: 500;
  text-align: center;
}
.digigold-paymethod-title{
  font-size: 18px;
  font-weight: 500;
}
.digigold-payment-discount{
  padding: 1.5rem 1.5rem;
  margin-bottom: 15px;
  /* font-size: 16px;
  font-weight: 400; 
  color: var(--darkText); */
  border-radius: 10px;
}
.digigold-payment-discount:last-child{
  margin-bottom: 30px;
}
.digigold-paymet-discount-info{
  display: flex;
}

.digigold-payment-discount .digigold-payment-discount-img{
  margin: 0 17px 0px 21px;
  width: 24px;
}
.digigold-payment-debit-vips{
  margin: 0 15px 0px 21px;
  width: 30px;
}
.digigold-payment-debit-payu{
  margin: 0 16px 0px 25px;
  width: 40px;
}

.digigold-paymet-amt-text{
  font-size: 16px;
  color: var(--darkText);
  margin-bottom: 0;
}
.digigold-paymet-discount-amt{
  color: var(--darkText);
  font-weight: 600;
  margin-bottom: 0;
  font-size: 16px;
  text-align: right;
}
.digigold-paymet-Prime-amt{
  color: var(--textGray);
  font-weight: 400;
  margin-bottom: 0;
  font-size: 16px;
  text-align: right;
}
.digigold-paymet-discount-info label{ 
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 400; 
  color: var(--darkText);
  display: flex;
  align-items: center;
}

.digigold-addmoney{
  background: var(--primary);
  padding: 2px 6px;
  font-size: 12px;
  font-weight: 500;
  color: var(--whiteColor) !important;
  text-decoration: none !important;
  border-radius: 6px;
  margin-left: 10px;
}

/* radio button end */
.digigold-order-proceed{
  text-align: center;
  margin-bottom: 35px;
}
.digigold-shopingpoint-errormsg{
  font-size: 12px;
  color: red;
  margin-left: 14px;
}
/* digital gold order summery page end */


/* order summery popup start */
.modal-close {
  color: var(--lightGray);
  font-size: 23px;
  font-weight: 100; 
  top: 0;
  z-index: 9;
  position: absolute;
  margin: 15px 20px;
}
.digigoldsummery-modal .modal-dialog {
  max-width: 450px !important;
  /* margin: 4.75rem auto; */
}

.digigoldsummery-modal .modal-content{
  border-radius: 10px;
  -webkit-box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 5%);
  -moz-box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 5%);
  box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 5%);
}

.digigold-summery-outer{
  padding: 55px 20px 20px;
}
.digigold-summery-title{
  font-size: 18px;
  font-weight: 500;
  border-bottom: 1px solid var(--borderColor);
  padding-bottom: 10px;
}
.payment-confirmation-summery{
  color: var(--textGray);
  font-size: 14px;
}
.payment-confirmation-summery-amt{
  color: var(--darkText);
  font-size: 14px;
  font-weight: 500;
}
.total-amt{
  font-weight: 500;
  color: var(--darkText);
}
.payment-confirmation-btn{
  text-align: center;
}
.payment-confirmation-btn button{
  font-size: 16px;
  font-weight: 500;
  background: none;  
  padding: 10px 49px;
  margin-top: 33px;
}

/* order summery popup end */


/* success page styles start */
.digigold-order-success{
  padding-top: 20px;
}
.check-green-img{
  width: 78px;
}
.digigold-success-title{
  font-size: 24px;
  font-weight: 600;
  color: var(--successgreen);
}
.success-note{
  font-size: 18px;
  font-weight: 500;
  color: var(--secondary);
}
.digigold-success-btn{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  grid-column-gap: 20px;
  margin: 25px 0;
}
.digigold-success-btn button{
  width: 180px;
}
/* success page styles end */



@media only screen and (min-width: 320px) and (max-width: 413px){
  .digital-gold-section-head .section-head-title {
      font-size: 20px;
  }

  .digigold-logintext {
    padding: 14px 15px 20px;
  }
  .digigold-logintext-title {
      font-size: 20px;
  }
  .digigold-logintext-btn {
      padding: 7px 20px;
  }

  .my-vault-outer{
      padding: 0 !important;
  }
  .my-vault-badge {
      padding: 5px 20px;
      font-size: 14px;
  }
  .my-vault-inner {
      padding: 20px 12px;
  }
  .vault-value-text {
      font-size: 16px;
  }
  .vault-value-count {
      font-size: 14px;
  }

  .buy-sell-form-outer {
      padding: 20px 12px;
      border-radius: 10px 10px 0px 0px;
      width: 100%;
  }
  .buy-sell-tab-inner {
    width: 100%;
    margin: auto;
  }
  .buy-sell-option {  
      column-gap: 9%;
      margin: 20px auto;
  }
  .buy-sell-option h2 {
      font-size: 20px;
      padding: 0 8px;
  }

  .tab-pills .tab-pills-link {
      text-align: center;
      padding: 0.7rem 0.3rem;
      font-size: 14px;
      font-weight: 500;
      color: var(--primary);
  }
  .buy-sell-tab-inner .exchange-arrow-outer {
      width: 100%;
      margin: 10px auto;
  }
  .buy-sell-tab-inner .input {
      font-size: 14px;
  }
  .buy-sell-tab-inner .input input {
      font-size: 14px;
      height: 46px;
  }

  .current-rate-outer {
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 18px;
  }
  .current-rate .current-rate-title {
      font-size: 18px;
  }
  .current-rate .current-rate-amt {
      font-size: 14px;
  }

  .input-wrapper {
      width: 100%;
  }

  .sip-box-inner {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      grid-column-gap: 12px;
  }
  .sip-box-inner {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
  }
  .sip-box-inner .sip-amt {
      padding: 8px 5px;
      min-width: 70px;
      margin-bottom: 10px;
      font-size: 14px;
      font-weight: 500;
  }
  .sip-box-inner .monthly-sip {
      padding: 12px 24px;
      min-width: 100%;
      margin: auto;
  }
  .quick-buy {
      padding: 10px 41px;
      border-radius: 5px;
      font-size: 14px;
  }


  .digigold-service-box-outer {
      justify-content: center;
  }
  .digigold-service-box-inner {
      width: auto;
  }
  .digigold-service-div-box {
      padding: 18px 10px;
  }
  .digigold-service-div-box .digigold-service-icon {
      width: 56px;
      height: 56px;
  }
  .digigold-service-div-box .digigold-service-title h3 {
      font-size: 15px;
  }


  .digigold-work-box-inner {
      width: 100%;
  }
  .digigold-work-section-head .section-head-title {
      font-size: 20px;
      padding: 0;
      margin-bottom: 0;
  }
  .digigold-work-div-box .digigold-work-title {
    height: auto;
  }
  .digigold-work-div-box .digigold-work-title h3 {
      font-size: 16px;
  }
  .digigold-work-div-box .digigold-work-description {
      font-size: 13px;
  }


  .digigold-paymethod-title {
    font-size: 15px;
    font-weight: 500;
  }
  .digigold-paymet-discount-info {
    display: flex;
    flex-wrap: wrap;
  }
  .digigold-payment-discount {
    padding: 1.5rem 1rem;
    border-radius: 10px;
  }
  .digigold-payment-debit-vips {
    margin: 0 5px 0px 9px;
    width: 26px;
  }
  .digigold-payment-discount .digigold-payment-discount-img {
    margin: 0 5px 0px 9px;
    width: 20px;
  }
  .digigold-payment-debit-payu {
    margin: 0 9px 0px 10px;
    width: 32px;
  }
  .digigold-addmoney {
    font-size: 12px;
    margin-left: 10px;
    margin-bottom: 10px;
  }
  .digigold-paymet-discount-info label {
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 400; 
    color: var(--darkText);
    flex-wrap: wrap;
  }
  .digigold-paymet-amt-text {
    font-size: 14px;
  }
  .digigold-paymet-discount-amt {
    font-size: 14px;
    text-align: left;
  }
  .digigold-shopingpoint-errormsg{
    margin-left: -17px;
    margin-top: 10px;
    line-height: 18px;
  }


  .check-green-img {
      width: 60px;
      margin-bottom: 10px;
  }
  .digigold-success-title {
      font-size: 22px;
  }
  .success-note {
      font-size: 14px;
  }
  .digigold-success-btn {
      grid-row-gap: 12px;
  }
  .digigold-success-btn button {
      width: auto;
  }

}

@media only screen and (min-width: 414px) and (max-width: 767px){

  .digital-gold-section-head .section-head-title {
      font-size: 20px;
  }
  .digigold-logintext-title {
      font-size: 20px;
  }
  .digigold-logintext-btn {
      padding: 7px 20px;
  }

  .my-vault-outer{
      padding: 0 !important;
  }
  .my-vault-badge {
      padding: 5px 20px;
      font-size: 14px;
  }
  .my-vault-inner {
      padding: 20px 12px;
  }
  .vault-value-text {
      font-size: 16px;
  }
  .vault-value-count {
      font-size: 14px;
  }

  .buy-sell-form-outer {
      padding: 20px 12px;
      border-radius: 10px 10px 0px 0px;
      width: 100%;
  }
  .buy-sell-option {  
      column-gap: 9%;
      margin: 20px auto;
  }
  .buy-sell-option h2 {
      font-size: 20px;
      padding: 0 8px;
  }

  .tab-pills .tab-pills-link {
      text-align: center;
      padding: 0.7rem 0.3rem;
      font-size: 14px;
      font-weight: 500;
      color: var(--primary);
  }
  .buy-sell-tab-inner .exchange-arrow-outer {
      width: 100%;
      margin: 10px auto;
  }
  .buy-sell-tab-inner .input {
      font-size: 14px;
  }
  .buy-sell-tab-inner .input input {
      font-size: 14px;
      height: 46px;
  }

  .current-rate-outer {
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 18px;
  }
  .current-rate .current-rate-title {
      font-size: 18px;
  }
  .current-rate .current-rate-amt {
      font-size: 14px;
  }

  .input-wrapper {
      width: 100%;
  }

  .sip-box-inner {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      grid-column-gap: 12px;
  }
  .sip-box-inner {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
  }
  .sip-box-inner .sip-amt {
      padding: 8px 5px;
      min-width: 70px;
      margin-bottom: 10px;
      font-size: 14px;
      font-weight: 500;
  }
  .sip-box-inner .monthly-sip {
      padding: 12px 24px;
      min-width: 100%;
      margin: auto;
  }
  .quick-buy {
      padding: 10px 41px;
      border-radius: 5px;
      font-size: 14px;
  }


  .digigold-service-box-outer {
      justify-content: center;
  }
  .digigold-service-box-inner {
      width: auto;
  }
  .digigold-service-div-box {
      padding: 18px 10px;
  }
  .digigold-service-div-box .digigold-service-icon {
      width: 56px;
      height: 56px;
  }
  .digigold-service-div-box .digigold-service-title h3 {
      font-size: 15px;
  }


  .digigold-work-box-inner {
      width: 100%;
  }
  .digigold-work-section-head .section-head-title {
      font-size: 20px;
      padding: 0;
      margin-bottom: 0;
  }
  .digigold-work-div-box .digigold-work-title {
    height: auto;
  }
  .digigold-work-div-box .digigold-work-title h3 {
      font-size: 16px;
  }
  .digigold-work-div-box .digigold-work-description {
      font-size: 13px;
  }


  .digigold-paymethod-title {
    font-size: 16px;
    font-weight: 500;
  }
  .digigold-paymet-discount-info {
    display: flex;
    flex-wrap: wrap;
  }
  .digigold-payment-discount {
    padding: 1.5rem 1rem 1rem;
    border-radius: 10px;
  }
  .digigold-payment-discount .digigold-payment-discount-img {
    margin: 0 5px 0px 9px;
    width: 20px;
  }
  .digigold-payment-debit-vips {
    margin: 0 5px 0px 9px;
    width: 28px;
  }
  .digigold-payment-debit-payu {
    margin: 0 10px 0px 10px;
    width: 32px;
  }
  .digigold-addmoney {
    font-size: 12px;
    margin-left: 8px;
  }
  .digigold-paymet-discount-info label {
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 400; 
    color: var(--darkText);
    flex-wrap: wrap;
  }
  .digigold-paymet-discount-amt {
    font-size: 16px;
    text-align: left;
  }
  .digigold-shopingpoint-errormsg {
    margin-left: -16px;
    margin-top: 10px;
    line-height: 18px;
  }

  

  .check-green-img {
      width: 60px;
      margin-bottom: 10px;
  }
  .digigold-success-title {
      font-size: 22px;
  }
  .success-note {
      font-size: 14px;
  }
  .digigold-success-btn {
      grid-row-gap: 12px;
  }
  .digigold-success-btn button {
      width: auto;
  }

}


@media only screen and (min-width: 600px) and (max-width: 767px){

  .buy-sell-form-outer {
      width: 100%;
  }
  .sip-box-inner .sip-amt {
      padding: 11px 5px;
      min-width: 86px;
      margin-bottom: 0;
  }
  .sip-box-inner .monthly-sip {
      padding: 11px 20px;
      min-width: 137px;
  }
  .digigold-work-box-inner {
      width: 50%;
  }
  .digigold-work-box-outer {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
  }

  .digigold-paymet-discount-amt {
    font-size: 16px;
    text-align: right;
  }

}


@media only screen and (min-width: 768px) and (max-width: 991px){
  .digigold-paymet-discount-info {
    display: flex;
    flex-wrap: wrap;
  }
  .digigold-paymet-discount-info label {
    flex-wrap: wrap;
    margin-bottom: 9px;
  }
  .digigold-addmoney {
    margin-left: 10px;
  }
  .digigold-shopingpoint-errormsg {
    margin-left: 10px;
    margin-top: 10px;
    line-height: 18px;
  }

  .vault-value-text {
      font-size: 22px;
  }
  .vault-value-count {
      font-size: 20px;    
  }
  .my-vault-badge {
      font-size: 16px;
  }

  .buy-sell-form-outer {
      width: 100%;
  }
  .sip-box-inner .sip-amt {
      padding: 11px 5px;
      min-width: 100px;
  }
  .sip-box-inner .monthly-sip {
      padding: 13px 20px;
      min-width: 182px;
  }
  .digigold-work-box-inner {
      width: 50%;
  }
  .digigold-work-box-outer {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
  }
  .digigold-work-div-box .digigold-work-title {
    height: auto;
  }


  .check-green-img {
      width: 64px;
      margin-bottom: 15px;
  }
  .digigold-success-title {
      font-size: 30px;
  }
  .success-note {
      font-size: 16px;
  }

}


@media only screen and (min-width: 992px) and (max-width: 1280px){
  .digigold-work-div-box .digigold-work-title {
    height: auto;
  }
  .digigold-work-div-box .digigold-work-title h3 {
    font-size: 16px;
  }
  .digigold-work-div-box {
    padding: 10px;
  }


  .digigold-payment-debit-vips {
    margin: 0 7px 0px 10px;
    width: 26px;
  }
  .digigold-payment-debit-payu {
    margin: 0 8px 0px 14px;
    width: 34px;
  }
  .digigold-paymet-discount-info label {
    font-size: 14px;
  }
  

}