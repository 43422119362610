/* ../../../../public/images/loginModal/rightBanner.png */

.mh-100{
    height: 100vh;
}
.mh-80{
    height: 80vh;
}

.form-group {
    position: relative;
    margin-bottom: 1.5rem;
}

.login-close {
    color: var(--blackColor);
    font-size: 30px;
    font-weight: 100; 
    top: 0;
    z-index: 9;
    position: absolute;
    margin: 22px 22px;
}
.titleMain img{
    width: 204px;
}

.login-btnCol{
    margin-top: 24px;
    
}
.login-btn{
    width: 160px;
    cursor: pointer;
    background-color: var(--primary);
    border-color: var(--primary);
    color: var(--whiteColor); 
}
.login-btn:hover{
    color: var(--whiteColor);
    background-color: var(--primary);
    border-color: var(--primary);
}

.login-modal .modal-dialog {
    /* max-width: 990px !important; */
    max-width: 834px !important;
    /* margin: 4.75rem auto; */
}

.login-modal .modal-content{
    border: none;
    border-radius: 10px;
    -webkit-box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 5%);
    -moz-box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 5%);
    box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 5%);
}

.modal-backdrop{
    position:fixed;
    top:0;
    left:0;
    z-index:1040;
    width:100vw;
    height:100vh;
    background: rgba(0, 0, 0, 0.7) !important; 
}

/******* login-page css start *********/

.loginForm {
    margin: 0 auto;
    padding: 70px 0px 70px 30px;
    text-align: center;
    max-width: 430px;
    /* background-color: var(--whiteColor); */
    position: relative;
}
.logform i{
    cursor: pointer;
    position: absolute;
    right: 0;
    bottom: 10px;
    font-size: 16px;
    color: var(--lightGray);
}
.logform img{
    cursor: pointer;
    position: absolute;
    right: 0;
    bottom: 10px;
    font-size: 16px;
    width: 16px;
    color: var(--lightGray);
}

.titleMain h2{
    margin-top: 18px;
    color: var(--secondary);
    font-size: 24px;
    font-weight: 500;
}

.titleMain.formText {
    padding-bottom: 25px;
}

.formStyle .form-group {
    margin-bottom: 30px;
}
 
.forgotPassword > a {
    color: var(--textGray);
    font-size: 13px;
    text-decoration: underline;
}
.forgotPassword > a:hover {
    color: var(--primary);
}

.notMember a{
    color: var(--primary);
    text-decoration: underline;
}
.notMember p {
    margin: 0;
    font-size: 14px;
    color: var(--textGray);
}

.loginBgCol {
    /* background-color: var(--secondary); */
    background-image: url('../../../../public/images/loginModal/rightBanner.svg');
    background-position: center center;
    -webkit-background-size: contain;
    background-size: contain;
    background-repeat: no-repeat;
    border-radius: 0px 10px 10px 0px;
}
.loginLogoCol {
    padding: 43px 42px 0px 34px;
    position: relative;
    z-index: 1;
    text-align: right;
    color: var(--whiteColor);
}
.loginLogoCol h3{
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 8px;
}
.loginLogoCol p{
    font-size: 12px;
    margin-bottom: 30px;
}
.loginLogoCol a {
    margin: 0 auto;
    padding: 8px 20px;
    border-radius: 6px;
    color: var(--whiteColor) !important;
    border: 1px solid var(--whiteColor) !important;
    font-weight: 500;
}
.loginLogoCol a:hover {
    text-decoration: none;
}
.no-gutters1 {
    margin-right: -1px;
    margin-left: 0px;
}

.right-logo-img{
    width: 200px;
}

.loginLogoCol .loginshapeImg {
    position: absolute;
    left: -50px;
    bottom: 0;
}
/******* login-page css end *********/


 
/* Responsive start */
 
@media only screen and (min-width: 320px) and (max-width: 413px){
    .login-modal .modal-dialog {
        max-width: 310px !important;
        /* margin: 3.75rem auto; */
    }
    .login-close {
        font-size: 30px !important;
        margin: 15px 15px;
    }
    .btnTopSpace {
        margin-top: 30px;
    }
    .titleMain img {
        width: 160px;
    }
    .titleMain h2 {
        font-size: 18px;
        margin-top: 17px;
    }
    .loginForm { 
        padding: 41px 10px; 
    }
    .logform input {
        font-size: 14px;
    }
    .logform label {
        font-size: 14px;
    }
    .login-btn {
        font-size: 14px !important;
    }
}

@media only screen and (min-width: 414px) and (max-width: 767px){
    .login-modal .modal-dialog {
        max-width: 341px !important;
        /* margin: 8.75rem auto; */
    }
    .headerCol {
        padding: 20px 0;
    }
    .login-close {
        margin: 17px 20px;
    }
    .titleMain img {
        width: 160px;
    }
    .mbTopSpace {
        padding-top: 22px !important;
    }
    .loginForm {
        padding: 22px 20px;
        max-width: 100%;
    }
    .logoCol img {
        width: 150px;
    }
    .titleMain h2 {
        font-size: 20px;
        margin-top: 17px;
    }
    .logform input {
        font-size: 14px;
    }
    .logform label {
        font-size: 14px;
    }
    .login-btn {
        font-size: 14px !important;
      }
}

@media only screen and (min-width: 600px) and (max-width: 767px){
    .login-modal .modal-dialog {
        max-width: 460px !important;
        /* margin: 8.75rem auto; */
    }

}

@media only screen and (min-width: 768px) and (max-width: 991px){
    .login-modal .modal-dialog {
        max-width: 531px !important;
        /* margin: 15.75rem auto; */
    }
    .login-close {
        margin: 19px 20px;
    }
    .loginForm {
        padding: 46px 30px;
    }
    .headerCol {
        border-bottom: 1px solid var(--lightBorder);
        padding: 28px 0;
    }
    .logoCol > a {
        display: inline-block;
    }
    .logoCol img {
        width: 150px;
    }
    .titleMain h2 {
        font-size: 20px;
        margin-top: 19px;
    }
    .loginBgCol {
        border-radius: 0;
        padding: 40px 20px;
    }
    .loginLogoCol > a {
        margin: 0 auto;
        max-width: 280px;
        padding: 15px;
    }
    .loginLogoCol > a > img {
        max-width: 220px;
    }
        
    .formStyle .form-group {
        margin-bottom: 25px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1280px){
    .login-modal .modal-dialog {
        max-width: 775px !important;
        /* margin: 14.75rem auto; */
    }
    .headerCol {
        border-bottom: 1px solid var(--lightBorder);
        padding: 28px 0;
    }
    .titleMain img {
        width: 180px;
    }
    .login-close {
        margin: 19px 22px;
    }
    .titleMain h2 {
        font-size: 20px;
        margin-top: 17px;
    }
    .loginForm {
        padding: 51px 30px;
    }
	.loginBgCol {
	    border-radius: 0;
	    padding: 40px 20px;
	}
	.loginLogoCol > a {
        margin: 0 auto;
        padding: 8px 20px;
        border-radius: 6px;
        color: var(--whiteColor) !important;
        border: 1px solid var(--whiteColor) !important;
        font-weight: 500;
    }
	.loginLogoCol > a > img {
    	max-width: 220px;
	}
    .loginLogoCol {
        padding: 36px 0px 0px 9px; 
    }
	   
    .formStyle .form-group {
        margin-bottom: 25px;
    }
} 