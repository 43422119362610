
.service-page-section-align{
    margin-top: 20px;
    margin-bottom: 20px;
  }

/* promo app banner section start */
.service-promo-banner{
    margin-bottom: 2%;
}
.service-promo-banner-content{
    padding-top: 9%;
}
.service-promo-banner-title{
    color: var(--darkText);
    font-size: 42px;
    font-weight: 600;
    line-height: 1.3;
    margin-bottom: 30px;
}
.service-promo-banner-title span{
    color: var(--secondary); 
}
.service-promo-banner-p{
    color: var(--darkText);
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 16px;
}
.services-promo-app-btn .btn{
    padding: 11px 0px;
    font-size: 17px;
    font-weight: 500;
    /* margin-top: 20px; */
}

.service-promo-banner-image{ 
    text-align: right;
}
.service-promo-banner-image img{
    height: 546px; 
}
/* promo app banner section start */



/* Recharge & Pay Bills on Vipswallet start */
  
.services-div-outer {
    text-decoration: none;
    color: var(--blackColor);
  }
.services-div-outer:hover {
    text-decoration: none;
    color: var(--blackColor);
}
.services-div-box {
    padding: 18px;
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    margin: 0 10px 18px 10px;
    /* background: var(--whiteColor);  */
    transition: all 0.3s ease-in-out;
    text-align: center;
  }
  
 .services-div-box:hover {
    transform: scale(1.1);
  }
  
.services-div-box .services-page-icon {
    margin: 0 auto 15px auto;
    /* padding-top: 19px; */
    /* display: inline-block; */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-align: center;
    border-radius: 50%;
    width: 70px;
    height: 70px;
     
    background: rgb(223,219,255); 
    background: -moz-linear-gradient(top,  rgba(223,219,255,1) 0%, rgba(236,234,255,1) 100%); 
    background: -webkit-linear-gradient(top,  rgba(223,219,255,1) 0%,rgba(236,234,255,1) 100%); 
    background: linear-gradient(to bottom,  rgba(223,219,255,1) 0%,rgba(236,234,255,1) 100%); 
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dfdbff', endColorstr='#eceaff',GradientType=0 ); 

}

.services-div-box .services-page-icon .service-icon {
    width: 38px;
    line-height: 1;
  }

.services-div-box .services-page-icon-2 {
    margin: 0 auto 15px auto;
    padding-top: 21px;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    width: 70px;
    height: 70px;
     
    background: rgb(223,219,255); 
    background: -moz-linear-gradient(top,  rgba(223,219,255,1) 0%, rgba(236,234,255,1) 100%); 
    background: -webkit-linear-gradient(top,  rgba(223,219,255,1) 0%,rgba(236,234,255,1) 100%); 
    background: linear-gradient(to bottom,  rgba(223,219,255,1) 0%,rgba(236,234,255,1) 100%); 
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dfdbff', endColorstr='#eceaff',GradientType=0 ); 

}
 
.services-div-box .services-page-icon-2 .service-icon {
    width: 38px;
    line-height: 1;
}
   
  
.services-div-box .service-title { 
    margin-bottom: 15px; 
}
  
.services-div-box .service-title h3 {
    color: var(--darkText);
    font-size: 16px;
    font-weight: 500;
}
  
.services-div-box  .description {
    font-size: 14px; 
    margin-bottom: 0;
    text-align: center;
}
  /* Recharge & Pay Bills on Vipswallet end */
  

  /* Earn Cashback banner 1 start */
  
  .services-bottom-advt {
    display: flex;
    position: relative;
    padding: 2rem 3rem 1rem;
    color: var(--darkText); 
  }
  .services-bottom_hero-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center; 
  }
  .services-bottom-banner_content { 
    position: relative;
  }
  .services-bottom-banner_title {
    font-size: 36px;
    font-weight: 500;
  }
  .services-bottom-banner_description{
    font-size: 28px;
    font-weight: 300;
  }
  /* Earn Cashback banner 1 start */




/* new styles start */
.services-box-outer{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.services-box-inner{
    width: 25%;
}
/* new styles end */



@media only screen and (min-width: 320px) and (max-width: 413px){
    .service-promo-banner-content {
        padding-top: 0%;
        padding-left: 22px !important;
        padding-right: 22px !important;
    }
    .service-promo-banner-title { 
        font-size: 18px;
        margin-bottom: 14px;
        line-height: 1.4;
    }
    .service-promo-banner-p { 
        font-size: 13px;
        margin-bottom: 10px;
    }
    .services-promo-app-btn .btn {
        padding: 11px 0px;
        font-size: 15px;
    }
    .service-promo-banner-image { 
        text-align: center;
        margin-top: 20px    ;
    }
    .service-promo-banner-image img {
        height: 434px;
    }


    .services-box-inner {
        width: auto;
    }
    .services-div-box {
        padding: 0;
        margin: 0 0px 12px 0px;
    }
    .services-div-box .service-title h3 { 
        font-size: 14px;
        font-weight: 500;
        width: 100px;
    }
    .services-div-box .services-page-icon {
        padding-top: 0px;
        width: 50px;
        height: 50px;
    }
    .services-div-box .services-page-icon .service-icon {
        width: 23px;
        line-height: 1;
    }
    .services-div-box .services-page-icon-2 {
        padding-top: 17px;
        width: 50px;
        height: 50px;
    }
    .services-div-box .services-page-icon-2 .service-icon {
        width: 23px;
        line-height: 1;
    }



    .services-bottom-advt { 
        padding: 2rem 2rem 1rem; 
    }
    .services-bottom-banner_title {
        font-size: 18px; 
    }
    .services-bottom-banner_description {
        font-size: 14px; 
    }

}


@media only screen and (min-width: 414px) and (max-width: 767px){
    .service-promo-banner-content {
        padding-top: 0%;
        padding-left: 24px !important;
        padding-right: 24px !important;
    }
    .service-promo-banner-title { 
        font-size: 18px;
        margin-bottom: 14px;
        line-height: 1.4;
    }
    .service-promo-banner-p { 
        font-size: 13px;
        margin-bottom: 7px;
    }
    .services-promo-app-btn .btn {
        padding: 11px 0px;
        font-size: 15px; 
    }
    .service-promo-banner-image { 
        text-align: center;
        margin-top: 20px    ;
    }
    .service-promo-banner-image img {
        height: 434px;
    }



    .services-box-inner {
        width: auto;
    }
    .services-div-box {
        padding: 0; 
        margin: 0 0px 12px 0px; 
    }
    .services-div-box .service-title h3 { 
        font-size: 14px;
        font-weight: 500;
        width: 100px;
    }
    .services-div-box .services-page-icon {
        padding-top: 0px;
        width: 50px;
        height: 50px;
    }
    .services-div-box .services-page-icon .service-icon {
        width: 23px;
        line-height: 1;
    }
    .services-div-box .services-page-icon-2 {
        padding-top: 17px;
        width: 50px;
        height: 50px;
    }
    .services-div-box .services-page-icon-2 .service-icon {
        width: 23px;
        line-height: 1;
    }



    
    .services-bottom-advt { 
        padding: 2rem 2rem 1rem; 
    }
    .services-bottom-banner_title {
        font-size: 20px; 
    }
    .services-bottom-banner_description {
        font-size: 14px; 
    }


}

@media only screen and (min-width: 540px) and (max-width: 768px){
    .service-promo-banner-content {
        padding-top: 0%;
        padding-left: 30px !important;
        padding-right: 30px !important;
    }
    .services-box-outer {
        grid-column-gap: 17px;
    }
    .services-box-inner {
        width: auto;
    }
    /* .services-div-box {
        padding: 0; 
        margin: 0 10px 12px 0px; 
    } */
    .services-div-box .service-title h3 { 
        font-size: 14px;
        font-weight: 500;
        width: 100px;
    }

}

@media only screen and (min-width: 640px) and (max-width: 767px){
    .service-promo-banner-content {
        padding-top: 4%;
        padding-left: 12px !important;
        padding-right: 12px !important;
    }

    .service-promo-banner-image {
        text-align: right;
        margin-top: 10px;
    }
    .service-promo-banner-image img {
        height: 314px;
    }

}


@media only screen and (min-width: 768px) and (max-width: 992px){
    .service-promo-banner-content {
        padding-top: 3%;
        /* padding-left: 15px !important;
        padding-right: 15px !important; */
    }
    .service-promo-banner-title { 
        font-size: 24px;
        margin-bottom: 15px;
    }
    .service-promo-banner-p { 
        font-size: 14px; 
        margin-bottom: 5px;
    }
    .service-promo-banner-image img {
        height: 341px;
    }


    .services-div-box {
        padding: 18px 0px 0; 
        margin: 0 10px 12px 10px; 
    }
    .services-div-box .service-title h3 { 
        font-size: 14px;
        font-weight: 600;
    }


    .services-bottom-advt { 
        padding: 2rem 2rem 1rem; 
    }
    .services-bottom-banner_title {
        font-size: 24px; 
    }
    .services-bottom-banner_description {
        font-size: 18px; 
    }

}


@media only screen and (min-width: 992px) and (max-width: 1279px){
    .service-promo-banner-title { 
        font-size: 28px; 
    }
    .service-promo-banner-p { 
        font-size: 14px; 
    }
    .service-promo-banner-image img {
        height: 467px;
    }


    .services-div-box .service-title h3 { 
        font-size: 16px;
        font-weight: 500;
    }

}


@media only screen and (min-width: 1280px) and (max-width: 1440px){
    .service-promo-banner-title { 
        font-size: 32px; 
    }
    .service-promo-banner-p { 
        font-size: 16px; 
    }
    .service-promo-banner-image img {
        height: 531px;
    }


    .services-bottom-banner_title {
        font-size: 30px;
        font-weight: 500;
    }
    .services-bottom-banner_description {
        font-size: 24px;
        font-weight: 300;
    }

}