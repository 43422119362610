/* ../../../../public/images/loginModal/otpRightBanner.png */


mh-100{
    height: 100vh;
}
.mh-80{
    height: 80vh;
}


.form-control-placeholder {
    position: absolute;
    top: 0;
    padding: 7px 0 0 13px;
    transition: all 200ms;
    opacity: 0.5;
    color: var(--lightGray)
}

.otp-close {
    color: var(--blackColor);
    font-size: 24px;
    font-weight: 100; 
    top: 0;
    z-index: 9;
    position: absolute;
    margin: 15px 20px;
}
.otp-titleMain img{
    width: 204px;
}

.otp-btnCol{
    margin-top: 24px;

    display: flex;
    justify-content: center;
    align-items: center;
    
}
.otp-btn{
    width: 192px;
    cursor: pointer;
    background-color: var(--primary);
    color: var(--whiteColor); 

    display: flex;
    justify-content: center;
    align-items: center;
}
.otp-btn:hover{
    color: var(--whiteColor);
}

.otp-modal .modal-dialog {
    /* max-width: 990px !important; */
    max-width: 834px !important;
    /* margin: 4.75rem auto; */
}

.otp-modal .modal-content{
    border-radius: 10px;
    -webkit-box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 5%);
    -moz-box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 5%);
    box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 5%);
}

.modal-backdrop{
    position:fixed;
    top:0;
    left:0;
    z-index:1040;
    width:100vw;
    height:100vh;
    background: rgba(0, 0, 0, 0.7) !important; 
}

/******* login-page css start *********/

.otpForm-outer {
    margin: 0 auto;
    padding: 70px 0px 70px 30px;
    text-align: center;
    max-width: 430px;
    /* background-color: var(--whiteColor); */
    position: relative;
}
.otpform-in i{
    cursor: pointer;
    position: absolute;
    right: 0;
    bottom: 10px;
    font-size: 16px;
    color: var(--lightGray);
}
.otpform-in img{
    cursor: pointer;
    position: absolute;
    right: 0;
    bottom: 10px;
    font-size: 16px;
    width: 16px;
    color: var(--lightGray);
}

.otpform-in{ 
    font-weight: 400 !important;
    position:relative;
    overflow:hidden;
    margin-bottom:20px;
  }
  .otpform-in input{
    width:100%;
    height:100%;
    color:var(--blackColor);
    padding-top: 9px;
    border:none;
    outline:none;
    font-size: 24px;
    font-weight: 400 !important;
    border-bottom: 1px solid var(--lightGray);
    position: relative;
  }
  .otpform-in input:focus{
    width:100%;
    height:100%;
    color:var(--blackColor);
    padding-top: 9px;
    border:none;
    outline:none;
    font-size: 24px;
    font-weight: 400 !important;
    border-bottom: 1px solid var(--primary);
  }
  .otpform-in input::after{
    width:100%;
    height:100%;
    color:var(--blackColor);
    padding-top: 9px;
    border:none;
    outline:none;
    font-size: 24px;
    font-weight: 400 !important;
    border-bottom: 1px solid var(--primary);
  }
  .otpform-in input{
    margin: 0.75rem !important;
    height: auto !important;
  }

  /* .otpform-in label {
    font-size: 16px;
    color: var(--textGray);
    font-weight: normal;
    position: absolute;
    width:100%;
    height:100%;
    bottom:0px;
    left:0%;
    pointer-events:none;
    border-bottom:1px solid #DEDEDE;
    margin-bottom: 0;
  }
  .otpform-in label::after{
    content:"";
    position:absolute;
    width:100%;
    height:100%;
    border-bottom:2px solid var(--primary);
    bottom:0px;
    left:0px;
    transform:translateX(-100%);
    transition:all 0.3s ease;
  }
  .content-name{
    position:absolute;
    bottom:5px;
    left:0px;
    transition:all .3s ease;
  }
  .otpform-in input:focus + .label-name .content-name ,
  .otpform-in input:valid+ .label-name .content-name{
    transform:translateY(-100%);
    font-size:14px;
    color:var(--primary);
  }
  .otpform-in input:focus+ .label-name::after, 
  .otpform-in input:valid+ .label-name::after {
    transform:translateX(0%);
     
  } */

.otp-titleMain h2{
    margin-top: 18px;
    color: var(--secondary);
    font-size: 24px;
    font-weight: 500;
}

.otp-titleMain.formText {
padding-bottom: 15px;
}
 
.resendotp a{
    color: var(--primary) !important;
}
.resendotp p {
margin: 0;
font-size: 14px;
color: var(--textGray);
}

.otp-send-to{
    margin-top: 12px;
}
.otp-send-to label{
    color: var(--blackColor);
    font-weight: 600;
}
.otp-send-to p {
margin: 0;
font-size: 14px;
color: var(--textGray);
}

.otpBgCol {
/* background-color: var(--secondary); */
background-image: url('../../../../public/images/loginModal/otpRightBanner.svg');
background-position: center center;
-webkit-background-size: auto;
background-size: auto;
background-repeat: no-repeat;
border-radius: 0px 10px 10px 0px;
}
.otpLogoCol {
padding: 43px 42px 0px 34px;

position: relative;
z-index: 1;
text-align: right;
color: var(--whiteColor);
}
.otpLogoCol h3{
    font-size: 20px;
    font-weight: 600;
}
.otpLogoCol p{
    font-size: 12px; 
}

.no-gutters1 {
    margin-right: -1px;
    margin-left: 0px;
}

.right-logo-img{
    width: 200px;
}

.otpLogoCol .loginshapeImg {
position: absolute;
left: -50px;
bottom: 0;
}
/******* login-page css end *********/
 
 
/* Responsive start */
 
@media only screen and (min-width: 320px) and (max-width: 413px){
    .otp-titleMain img {
        width: 160px;
    }
    .otp-titleMain h2 {
        margin-top: 18px;
        font-size: 18px;
    }
    .otp-close {
        font-size: 20px !important;
    }
    .btnTopSpace {
        margin-top: 30px;
    }  
    /* .otp-modal .modal-dialog {
        max-width: 320px !important;
        margin: 3.75rem auto;
    } */
    .otpForm-outer { 
        padding: 41px 30px; 
    }
       
    .otp-titleMain.formText {
        padding-bottom: 8px;
    }
    .otpform-in input{
        margin: 0.5rem !important;
        font-weight: 400 !important;
    }
    .otp-btn {
        font-size: 14px !important;
    }
}

@media only screen and (min-width: 414px) and (max-width: 767px){
    /* .otp-modal .modal-dialog {
        max-width: 582px !important;
        margin: 8.75rem auto;
    } */
    .headerCol {
        padding: 20px 0;
    }
    .otp-close {
        font-size: 20px !important;
        margin: 17px 20px;
    }
    .otp-titleMain img {
        width: 160px;
    }
    .otp-titleMain h2 {
        margin-top: 18px;
        font-size: 18px;
    }
    .mbTopSpace {
        padding-top: 22px !important;
    }
    .otpForm-outer {
        padding: 22px 20px;
        max-width: 100%;
    }
    .otpform-in input{
        margin: 0.5rem !important;
        font-weight: 400 !important;
    }
    .otp-btn {
        width: 192px;
        cursor: pointer;
        font-size: 14px !important;
    }
}


@media only screen and (min-width: 600px) and (max-width: 767px){
    .otp-modal .modal-dialog {
        max-width: 460px !important;
        /* margin: 8.75rem auto; */
    }

}

@media only screen and (min-width: 768px) and (max-width: 991px){
    .otp-modal .modal-dialog {
        max-width: 531px !important;
        /* margin: 15.75rem auto; */
    }
    .otp-close {
        font-size: 22px !important;
    }
    .otp-titleMain h2 {
        margin-top: 19px;
        font-size: 20px;
    }
    .headerCol {
        border-bottom: 1px solid var(--lightBorder);
        padding: 28px 0;
    }
     
    .otpBgCol {
        border-radius: 0;
        padding: 40px 20px;
    }
    .otpForm-outer {
        padding: 46px 30px;
    }
        
}

@media only screen and (min-width: 992px) and (max-width: 1280px){
    .otp-modal .modal-dialog {
        max-width: 775px !important;
        /* margin: 14.75rem auto; */
    }
    .otp-close {
        font-size: 22px !important;
    }
    .headerCol {
        border-bottom: 1px solid var(--lightBorder);
        padding: 28px 0;
    }
    .otp-titleMain img {
        width: 180px;
    }
    .otp-titleMain h2 {
        font-size: 20px;
        margin-top: 17px;
    }
    .otpform-in input{
        margin: 0.5rem !important;
        font-weight: 400 !important;
    }
    .otpForm-outer {
        padding: 51px 30px;
    }
	.otpBgCol {
	    border-radius: 0;
	    padding: 40px 20px;
	}
    .otpLogoCol {
        padding: 36px 0px 0px 9px; 
    }
	
}  