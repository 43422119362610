.floating-input-wrapper {
  position: relative;
  margin-bottom: 20px;
}
.floating-textarea-box {
  height: 110px !important;
}
.floating-label-name {
  color: var(--textGray);
  font-size: 13px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 15px;
  top: 14px;
  padding: 0 5px;
  background: var(--whiteColor);
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

.floating-input-box,
.floating-select-wraper {
  font-size: 16px;
  display: block;
  width: 100%;
  height: 45px;
  padding: 0 20px;
  background: #fff;
  color: var(--darkText);
  border: 1px solid var(--borderColor);
  border-radius: 4px;
  box-sizing: border-box;
}
.floating-input-box:focus,
.floating-select-wraper:focus {
  outline: none;
}
.floating-input-box:focus ~ .floating-label-name,
.floating-select-wraper:focus ~ .floating-label-name {
  top: -8px;
  font-size: 13px;
}

select.floating-select-wraper {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

select.floating-select-wraper::-ms-expand {
  display: none;
}

.floating-input-box:not(:placeholder-shown) ~ .floating-label-name {
  top: -8px;
  font-size: 13px;
  color: var(--textGray);
}

.floating-select-wraper:not([value=""]):valid ~ .floating-label-name {
  top: -8px;
  font-size: 13px;
}

.floating-select-wraper[value=""]:focus ~ .floating-label-name {
  top: 11px;
  font-size: 13px;
}

.floating-select-wraper:not([multiple]):not([size]) {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='6' viewBox='0 0 8 6'%3E%3Cpath id='Path_1' data-name='Path 1' d='M371,294l4,6,4-6Z' transform='translate(-371 -294)' fill='%23003d71'/%3E%3C/svg%3E%0A");
  background-position: right 15px top 50%;
  background-repeat: no-repeat;
}

.did-error-input .floating-input-box,
.did-error-input .floating-select-wraper {
  border: 2px solid var(--primary);
  color: var(--primary);
}
.did-error-input .floating-label-name {
  font-weight: 600;
  color: var(--primary);
}
.did-error-input .floating-select-wraper:not([multiple]):not([size]) {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='6' viewBox='0 0 8 6'%3E%3Cpath id='Path_1' data-name='Path 1' d='M371,294l4,6,4-6Z' transform='translate(-371 -294)' fill='%239d3b3b'/%3E%3C/svg%3E%0A");
}

.order-proceed-btn {
  text-align: center;
  margin: 0 0px 30px;
}
.order-proceed-btn button {
  padding: 9px 60px !important;
}

.user-bank-details {
  background: var(--body-bg);
  padding: 15px;
  border-radius: 10px;
  font-size: 14px;
  color: var(--darkText);
  margin-top: 20px;
  line-height: 2.3;
}
.user-bank-details .text-gray {
  color: var(--textGray);
}
.edit-bank-details {
  margin-right: 10px;
  color: var(--secondary);
  background: none;
  border: none;
}


@media (max-width: 575px) {
  .order-proceed-btn {
    text-align: center;
    margin: 20px 0px 30px;
  }
}
