.services-div {
  text-decoration: none;
  color: var(--blackColor);
}
.services-div:hover {
  text-decoration: none;
  color: var(--blackColor);
}
.services .services-box {
  padding: 15px 9px;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  margin: 0 10px 40px 10px;
  /* background: var(--whiteColor);
  -webkit-box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 5%);
  -moz-box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 5%);
  box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 5%); */
  transition: all 0.3s ease-in-out;
  text-align: center;
}

.services .services-box:hover {
  transform: scale(1.1);
}

.services .services-box-icon {
  margin: 0 auto 15px auto;
  padding-top: 0px;
  /* display: inline-block; */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  /* background: var(--secondary); */

  background: rgb(223,219,255); 
  background: -moz-linear-gradient(top,  rgba(223,219,255,1) 0%, rgba(236,234,255,1) 100%); 
  background: -webkit-linear-gradient(top,  rgba(223,219,255,1) 0%,rgba(236,234,255,1) 100%); 
  background: linear-gradient(to bottom,  rgba(223,219,255,1) 0%,rgba(236,234,255,1) 100%); 
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dfdbff', endColorstr='#eceaff',GradientType=0 );

  -webkit-box-shadow: 0px 1px 3px 1px rgb(0 0 0 / 7%);
  -moz-box-shadow: 0px 1px 3px 1px rgb(0 0 0 / 7%);
  box-shadow: 0px 1px 3px 1px rgb(0 0 0 / 7%);
}

.services .services-box-icon .service-icon {
  width: 38px;
  line-height: 1;
}

.services .service-title {
  font-weight: 600;
  margin-bottom: 10px;
  font-size: 18px;
}

.services .service-title h3 {
  color: var(--darkText);
  font-size: 16px;
  font-weight: 500;
}

.services .description {
  font-size: 13px; 
  margin-bottom: 0;
  text-align: center;
}



@media only screen and (min-width: 320px) and (max-width: 413px){
  .services .services-box {
    padding: 20px 30px;
    margin: 0 10px 10px 10px;
  }
  .services .services-box-icon {
    padding-top: 0px;
    width: 64px;
    height: 64px;
  }
  .services .services-box-icon .service-icon {
    width: 28px;
    line-height: 1;
  }

}


@media only screen and (min-width: 414px) and (max-width: 767px){
  .services .services-box {
    padding: 20px 30px;
    margin: 0 10px 10px 10px;
  }
  .services .services-box-icon {
    padding-top: 0px;
    width: 64px;
    height: 64px;
  }
  .services .services-box-icon .service-icon {
    width: 28px;
    line-height: 1;
  }

}

@media only screen and (min-width: 640px) and (max-width: 767px){
  .services .services-box {
    padding: 20px 5px;
  }

}

@media only screen and (min-width: 768px) and (max-width: 991px){
  .services .services-box {
    padding: 5px;
  }

}

@media only screen and (min-width: 1024px) and (max-width: 1280px){
  .services .services-box {
    padding: 15px 5px;
  }

}