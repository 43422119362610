

.login-close {
  color: var(--lightGray);
  font-size: 30px;
  font-weight: 100; 
  top: 0;
  z-index: 9;
  position: absolute;
  margin: 22px 22px;
}
.titleMain img{
  width: 204px;
}

.login-btnCol{
  margin-top: 24px;
  
}


.login-modal .modal-dialog {
  /* max-width: 990px !important; */
  /* margin: 4.75rem auto; */
}

.login-modal .modal-content{
  border: none;
  border-radius: 10px;
  -webkit-box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 5%);
  -moz-box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 5%);
  box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 5%);
}

.modal-backdrop{
  position:fixed;
  top:0;
  left:0;
  z-index:1040;
  width:100vw;
  height:100vh;
  background: rgba(0, 0, 0, 0.7) !important; 
}

/******* login-page css start *********/

.digi-gold-loginForm {
  margin: 0 auto;
  padding: 15px 10px 30px;
  text-align: center;
  max-width: 430px;
  /* background-color: var(--whiteColor); */
  position: relative;
}
.digi-gold-loginForm .titleMain h2{
  margin-top: 8px;
  color: var(--secondary);
  font-size: 24px;
  font-weight: 500;
}
.digi-gold-loginForm .signin-subtitle{
  font-size: 16px;
}

.digi-gold-loginForm .titleMain.formText {
  padding-bottom: 15px;
}

.notMember a{
  color: var(--primary);
  text-decoration: underline;
}
.notMember p {
  margin: 0;
  font-size: 14px;
  color: var(--textGray);
}

.no-gutters1 {
  margin-right: -1px;
  margin-left: 0px;
}



.prepend-input-wrapper {
  position: relative;
  margin-bottom: 20px;
}

.prepend-floating-label {
  color: var(--textGray);
  font-size: 16px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 50px;
  top: 12px;
  padding: 0 5px;
  background: #fff;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

.select-input-wrapper {
  position: relative;
  margin-bottom: 20px;
}
.select-floating-label {
  color: var(--textGray);
  font-size: 16px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 10px;
  top: 12px;
  padding: 0 5px;
  background: #fff;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
.prepend-floating-input, .did-floating-select {
  font-size: 16px;
  display: block;
  width: 100%;
  height: 45px;
  padding: 0 16px;
  background: #fff;
  color: var(--darktext);
  border: 1px solid var(--lightGray);
  border-radius: 4px;
  box-sizing: border-box;
}
.prepend-floating-input:focus, .did-floating-select:focus {
  outline: none;
}
.prepend-floating-input:focus ~ .prepend-floating-label, .did-floating-select:focus ~ .select-floating-label {
  top: -8px;
  font-size: 13px;
  color: var(--primary) !important;
}

select.did-floating-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

select.did-floating-select::-ms-expand {
  display: none;
}

.prepend-floating-input:not(:placeholder-shown) ~ .prepend-floating-label {
  top: -8px;
  font-size: 13px;
  color: var(--primary);
}

.did-floating-select:not([value=""]):valid ~ .select-floating-label {
  top: -8px;
  font-size: 13px;
}

.did-floating-select[value=""]:focus ~ .select-floating-label {
  top: 11px;
  font-size: 13px;
  
}

.did-floating-select:not([multiple]):not([size]) {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='6' viewBox='0 0 8 6'%3E%3Cpath id='Path_1' data-name='Path 1' d='M371,294l4,6,4-6Z' transform='translate(-371 -294)' fill='%23003d71'/%3E%3C/svg%3E%0A");
  background-position: right 15px top 50%;
  background-repeat: no-repeat;
}

.did-error-input .prepend-floating-input, .did-error-input .select-floating-label {
  border: 2px solid #9d3b3b;
  color: #9d3b3b;
}
.did-error-input .prepend-floating-label {
  font-weight: 600;
  color: #9d3b3b;
}
.did-error-input .select-floating-label:not([multiple]):not([size]) {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='6' viewBox='0 0 8 6'%3E%3Cpath id='Path_1' data-name='Path 1' d='M371,294l4,6,4-6Z' transform='translate(-371 -294)' fill='%239d3b3b'/%3E%3C/svg%3E%0A");
}

.prepend-input-wrapper.input-group {
  display: flex;
  flex-wrap: nowrap;
}
.prepend-input-wrapper.input-group .prepend-floating-input {
  border-radius: 0 4px 4px 0;
  border-left: 0;
  padding-left: 6px;
}

.input-group-append {
  display: flex;
  align-items: center;
  /*   margin-left:-1px; */
}

.prepend-input-wrapper .input-group-text {
  display: flex;
  align-items: center;
  font-weight: 400;
  height: 45px;
  color: var(--darktext);
  padding: 0 12px 0 12px;
  font-size: 16px;
  text-align: center;
  white-space: nowrap;
  border: 1px solid var(--lightGray);
  border-radius: 4px 0 0 4px;
  border-right: none;
  background: none;
}

.gold-signin-form .input-wrapper{
  width: 100%;
  margin-bottom: 20px;
}
.gold-signin-form .input {
  position: relative;
  font-size: 16px;
  background-color:inherit;
}

.gold-signin-form .input input {
  line-height:1;
  font-size: 16px;
  min-width: 100%;
  padding: 0 16px;
  height: 50px;
  border-radius: 4px;
  border: 1px solid var(--lightGray);
  /* box-shadow: 0 0 0 1px #a0aec0; */
  background:transparent;
  color:var(--darkText);
}
.gold-signin-form .input input::-webkit-input-placeholder  {
  opacity: 0;
  transition: opacity 200ms;
}
.gold-signin-form .input input::-moz-placeholder {
  opacity: 0;
  transition: opacity 200ms;
}
.gold-signin-form .input label {
  color: var(--textGray);
  position: absolute;
  top: 13px;
  left: 16px;
  transition: all 200ms;
}

.gold-signin-form .input input:focus {
  outline: none;
  /* box-shadow: 0 0 0 1px #63b3ed; */
}
.gold-signin-form .input input:focus::-webkit-input-placeholder {
  opacity: 0.5;
}
.gold-signin-form .input input:focus::-moz-placeholder {
  opacity: 0.5;
}
.gold-signin-form .input input:focus ~ label,
.gold-signin-form .input input:not(:placeholder-shown) ~ label {
  background-color: var(--whiteColor);
  padding: 1px 5px;
  border-radius: 3px;
  top: 7px;
  left: 0;
  transform: translate3d(0,-22px,0) scale(0.75)
}
.gold-signin-form .input input:focus ~ label {
  color: var(--primary);
}

.gold-signin-form .input input:valid:not(:placeholder-shown) {
  /* box-shadow: 0 0 0 1px var(--primary) !important; */
  /* border: 1px solid var(--primary); */
  box-shadow: none !important;
}
.gold-signin-form .input input:valid:not(:placeholder-shown) ~ label {
  color: var(--primary) !important;
  font-size: 18px;
}

.gold-signin-form .input input:invalid:not(:placeholder-shown) {
  box-shadow: 0 0 0 1px #ed8936 !important;
}
.gold-signin-form .input input:invalid:not(:placeholder-shown) ~ label {
  color: #ed8936 !important;
}


.check-term-Style{
  display: inline-flex !important;
  margin-top: 20px;
}

.check-term-Style a {
  color: var(--primary);
  text-decoration: underline;
}
.check-term-Style p {
  margin: 0;
  font-size: 14px;
  color: var(--secondary);
}

.check-term-Style input {
  padding: 0; 
  margin-bottom: 0; 
  cursor: pointer;
  width: 16px;
  border-color: var(--textGray);
}
.check-term-Style label {
  position: relative;
  cursor: pointer;
  margin: 0;
  display: block;
  color: var(--secondary);
  font-size: 13px;
  padding-left: 10px;
  padding-top: 3px;
}
.check-term-Style a{
  color: var(--secondary);
  text-decoration: none;
}

.buy-sell-tab-inner .input input:disabled{
  color: var(--darkText);
  border-color: var(--primary);
  box-shadow: none;
}
/******* login-page css end *********/


/* OTP styles start */

.otp-close {
  color: var(--lightGray);
  font-size: 23px;
  font-weight: 100; 
  top: 0;
  z-index: 9;
  position: absolute;
  margin: 15px 20px;
}
.otp-btnCol{
  margin-top: 24px;
  
}
.otp-btn{
  width: 192px;
  cursor: pointer;
}
.otp-btn:hover{
  color: var(--whiteColor);
}

.digigoldotp-modal .modal-dialog {
  max-width: 400px !important;
  /* margin: 4.75rem auto; */
}

.digigoldotp-modal .modal-content{
  border-radius: 10px;
  -webkit-box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 5%);
  -moz-box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 5%);
  box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 5%);
}

.digigoldotpForm-outer {
  margin: 0 auto;
  padding: 30px 30px;
  text-align: center;
  max-width: 430px;
  /* border: 1px solid var(--borderColor); */
  border-radius: 10px;
  position: relative;
  /* -webkit-box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 18%);
  -moz-box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 18%);
  box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 18%); */
}
.digigoldotpForm-in i{
  cursor: pointer;
  position: absolute;
  right: 0;
  bottom: 10px;
  font-size: 16px;
  color: var(--lightGray);
}

.digigoldotpForm-in{ 
  font-weight: 400 !important;
  position:relative;
  overflow:hidden;
  margin-bottom:20px;
}
.digigoldotpForm-in input{
  width:100%;
  height:100%;
  color:var(--blackColor);
  padding-top: 9px;
  border:none;
  outline:none;
  font-size: 24px;
  font-weight: 400 !important;
  border-bottom: 1px solid var(--lightGray);
  position: relative;
}
.digigoldotpForm-in input:focus{
  width:100%;
  height:100%;
  color:var(--blackColor);
  padding-top: 9px;
  border:none;
  outline:none;
  font-size: 24px;
  font-weight: 400 !important;
  border-bottom: 1px solid var(--primary);
}
.digigoldotpForm-in input::after{
  width:100%;
  height:100%;
  color:var(--blackColor);
  padding-top: 9px;
  border:none;
  outline:none;
  font-size: 24px;
  font-weight: 400 !important;
  border-bottom: 1px solid var(--primary);
}
.digigoldotpForm-in input{
  margin: 0.75rem !important;
}

.digigoldotp-titleMain h2{
  color: var(--secondary);
  font-size: 24px;
  font-weight: 500;
}

.digigoldotp-titleMain.formText {
  padding-bottom: 0px;
}

.resendotp a{
  color: var(--primary);
  text-decoration: underline;
}
.resendotp p {
  margin: 0;
  font-size: 14px;
  color: var(--secondary);
}

.otp-send-to{
  margin-top: 12px;
  margin-bottom: 25px;
}
.otp-send-to label{
  color: var(--blackColor);
  font-weight: 600;
}
.otp-send-to p {
  margin: 0;
  font-size: 14px;
  color: var(--textGray);
}

/* OTP styles end */

.ant-btn-primary{
  background: var(--primary);
  color: var(--whiteColor);
  box-shadow: 0 0 0 2px rgb(202 48 96 / 10%);
}
.ant-btn-primary:not(:disabled):hover{
  background: var(--primary);
  color: var(--whiteColor);
  box-shadow: 0 0 0 2px rgb(202 48 96 / 10%);
}

/* Responsive start */

@media only screen and (min-width: 320px) and (max-width: 413px){
  /* .login-modal .modal-dialog {
      max-width: 320px !important;
      margin: 3.75rem auto;
  } */
  .login-close {
      font-size: 20px !important;
  }
  .btnTopSpace {
      margin-top: 30px;
  }
  .titleMain img {
      width: 160px;
  }
  .digi-gold-loginForm .titleMain h2 {
      margin-top: 5px;
      font-size: 18px;
  }
  .digi-gold-loginForm .signin-subtitle {
      font-size: 14px;
  }
  .loginForm { 
      padding: 41px 30px; 
  }
  
  .login-btn {
      font-size: 14px !important;
  }

  .prepend-floating-label {
      font-size: 13px;
  }
  .prepend-floating-input, .did-floating-select {
      font-size: 13px;
  }
  .prepend-input-wrapper .input-group-text {
      padding: 0 9px 0 9px;
      font-size: 14px;
  }
  .gold-signin-form .input label {
      left: 11px;
      top: 15px;
  }
  .gold-signin-form .input {
      font-size: 13px;
  }
  .prepend-floating-label {
      left: 38px;
      top: 14px;
  }
  .select-floating-label {
      font-size: 13px;
  }
  .gold-signin-form .input input {
      font-size: 0px;
  }
  .digisign-otp-input input{
    margin-right: 9px !important;
  }
  .digisign-otp-input input:last-child{
    margin-right: 0px !important;
  }


  .Ordersummery-otp-input input{
    margin-right: 10px !important;
  }
  .Ordersummery-otp-input input:last-child{
    margin-right: 0px !important;
  }

}

@media only screen and (min-width: 414px) and (max-width: 767px){
  /* .login-modal .modal-dialog {
      max-width: 341px !important;
      margin: 8.75rem auto;
  } */
  .headerCol {
      padding: 20px 0;
  }
  .login-close {
      font-size: 20px !important;
  }
  .btnTopSpace {
      margin-top: 30px;
  }
  .titleMain img {
      width: 160px;
  }
  .digi-gold-loginForm .titleMain h2 {
      margin-top: 5px;
      font-size: 18px;
  }
  .digi-gold-loginForm .signin-subtitle {
      font-size: 14px;
  }
  .loginForm { 
      padding: 41px 30px; 
  }
  
  .login-btn {
      font-size: 14px !important;
  }

  .prepend-floating-label {
      font-size: 13px;
  }
  .prepend-floating-input, .did-floating-select {
      font-size: 13px;
  }
  .prepend-input-wrapper .input-group-text {
      padding: 0 9px 0 9px;
      font-size: 14px;
  }
  .gold-signin-form .input label {
      left: 11px;
      top: 15px;
  }
  .gold-signin-form .input {
      font-size: 13px;
  }
  .prepend-floating-label {
      left: 38px;
      top: 14px;
  }
  .select-floating-label {
      font-size: 13px;
  }
  .gold-signin-form .input input {
      font-size: 0px;
  }
}

@media only screen and (min-width: 600px) and (max-width: 767px){
  .login-modal .modal-dialog {
      max-width: 460px !important;
      /* margin: 8.75rem auto; */
  }

}

@media only screen and (min-width: 768px) and (max-width: 991px){
  .login-modal .modal-dialog {
      max-width: 531px !important;
      /* margin: 15.75rem auto; */
  }
  .login-close {
      margin: 19px 20px;
  }
  .loginForm {
      padding: 46px 30px;
  }
  .headerCol {
      border-bottom: 1px solid var(--lightBorder);
      padding: 28px 0;
  }
  .logoCol > a {
      display: inline-block;
  }
  .logoCol img {
      width: 150px;
  }
  .titleMain h2 {
      font-size: 20px;
      margin-top: 19px;
  }
  
    
}

@media only screen and (min-width: 992px) and (max-width: 1280px){
  .login-modal .modal-dialog {
      max-width: 775px !important;
      /* margin: 14.75rem auto; */
  }
  .headerCol {
      border-bottom: 1px solid var(--lightBorder);
      padding: 28px 0;
  }
  .titleMain img {
      width: 180px;
  }
  .login-close {
      margin: 19px 22px;
  }
  .titleMain h2 {
      font-size: 20px;
      margin-top: 17px;
  }
  .loginForm {
      padding: 51px 30px;
  }


} 