@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

:root {
  --primary: #ca3060 !important;
  --primaryHover: #ca3060 !important;
  --secondary: #393186 !important;
  --darkText: #212121 !important;
  --textGray: #777777 !important;
  --lightGray: #9ea5ad !important;
  --borderColor: #dedede !important;
  --lightBorder: #eaeaec !important;
  --whiteColor: #ffffff !important;
  --blackColor: #000000 !important;
  /* --body-bg: #F5F7FA !important; */
  --body-bg: #f8f9fb !important;
  --red: #ff0000 !important;
  --warning: #ffc107;
  --lightGreen: #3bb54a !important;
  --successgreen: #28a745 !important;
  --footerbottom: #e9e7f6;
  --sidesubNavbg: #e6ebed;
  --browncolor: #9a1717;
  --goldcolor: #e4a11e;
  --radiostrock: #cfd1ff;
  --badge: #f7a708;
  --box-bg: #f5f7fa !important;
  --gold: #d6992b !important;
  --addamtbtn: #c6c6c5 !important;
  --graybtn: #bababa !important;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif !important;
  font-size: 12px !important;
  line-height: 24px;
  font-weight: normal;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


/* input:autofill {
  background-color: #fff !important;
}
input:-internal-autofill-selected {
  background-color: #fff !important;
}

input:-webkit-autofill, input:-webkit-autofill:focus {
  background-color: #fff;
  -webkit-text-fill-color: #333 !important;
} */

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  background-color: #fff !important;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset !important;
  transition: background-color 5000s ease-in-out 0s !important;
}



.color-body {
  background-color: var(--body-bg) !important;
}

.clearfix {
  clear: both;
}


input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  background-color: #fff !important;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset !important;
  transition: background-color 5000s ease-in-out 0s !important;
}


.react-multi-carousel-list {
  position: unset !important;
}

.react-multiple-carousel__arrow--right {
  right: calc(4% + 1px);
}
.react-multiple-carousel__arrow--left {
  left: calc(4% + 1px);
}

.tab-content .react-multiple-carousel__arrow--right {
  right: calc(-9% + 1px);
}
.tab-content .react-multiple-carousel__arrow--left {
  left: calc(-9% + 1px);
}

.top-banner-outer .react-multi-carousel-dot-list {
  bottom: 0;
  top: 65% !important;
}
.react-multi-carousel-dot--active button {
  background: var(--primary) !important;
}
.react-multi-carousel-dot button {
  /* border-color: var(--primary) !important;
  background: var(--whiteColor); */
  border-color: rgba(202 48 96 / 4%) !important;
  background: rgba(202 48 96 / 26%);
}
.react-multiple-carousel__arrow {
  background: rgba(202, 48, 96, 0.7) !important;
}
.react-multiple-carousel__arrow:hover {
  background: var(--primary) !important;
}

.btn-primery {
  padding: 7px 24px;
  font-size: 16px;
  font-weight: 500;
  color: var(--whiteColor) !important;
  background: var(--primary) !important;
  border: 1px solid var(--primary) !important;
  border-radius: 8px;
  cursor: pointer;
  -webkit-box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 5%);
  -moz-box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 5%);
  box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 5%);
}
.btn-secondary {
  padding: 7px 24px;
  font-size: 14px;
  font-weight: 500;
  color: var(--whiteColor) !important;
  background: var(--secondary);
  border: 1px solid var(--secondary) !important;
  border-radius: 8px;
  cursor: pointer;
  -webkit-box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 5%);
  -moz-box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 5%);
  box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 5%);
}
.btn-disable {
  padding: 7px 24px;
  font-size: 16px;
  font-weight: 400;
  background: var(--primary) !important;
  opacity: 0.4 !important;
  color: var(--whiteColor) !important;
  border-color: var(--primary) !important;
  border-radius: 8px;
  cursor: pointer;
  -webkit-box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 8%);
  -moz-box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 8%);
  box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 8%);
}

.btn-cta {
  padding: 7px 24px;
  font-size: 14px;
  font-weight: 600;
  color: var(--primary) !important;
  border: 1px solid var(--primary) !important;
  border-radius: 8px;
  -webkit-box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 5%);
  -moz-box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 5%);
  box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 5%);
}

.box-shadow-1 {
  -webkit-box-shadow: 0px 0px 9px 1px rgb(104 104 104 / 10%);
  -moz-box-shadow: 0px 0px 9px 1px rgb(104 104 104 / 10%);
  box-shadow: 0px 0px 9px 1px rgb(104 104 104 / 10%);
  border: 1px solid var(--lightBorder);
}

.shadow-light {
  -webkit-box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 5%);
  -moz-box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 5%);
  box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 5%);
}

.shadow-dark {
  -webkit-box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 18%);
  -moz-box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 18%);
  box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 18%);
}

.inset-shaddow-top-light {
  box-shadow: 0px 2px 2px 0px rgba(0 0 0 / 5%) inset;
  -webkit-box-shadow: 0px 2px 2px 0px rgba(0 0 0 / 5%) inset;
  -moz-box-shadow: 0px 2px 2px 0px rgba(0 0 0 / 5%) inset;
}

.inset-shaddow-top-dark {
  box-shadow: 0px 2px 2px 0px rgba(0 0 0 / 18%) inset;
  -webkit-box-shadow: 0px 2px 2px 0px rgba(0 0 0 / 18%) inset;
  -moz-box-shadow: 0px 2px 2px 0px rgba(0 0 0 / 18%) inset;
}

.alert-danger {
  color: var(--darkText);
  border: none;
  background: #f4d5df;
}

/* breadcrumb start */
.breadcrumb-outer {
  position: relative;
  top: 70px;
  width: 90%;
  margin: auto;
  border-radius: 6px;
}
.breadcrumb {
  padding: 0.75rem 2.5rem !important;
  background-color: var(--secondary) !important;
}
.breadcrumb li a {
  color: var(--whiteColor) !important;
}
.breadcrumb .active {
  color: var(--whiteColor) !important;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: var(--whiteColor) !important;
  content: ">" !important;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
/* breadcrumb end */

/* banner slider start */
.banner-item .banner-img {
  width: 100%;
}
/* banner slider end */

.section-align {
  margin-top: 75px;
  margin-bottom: 50px;
}

.section-head {
  /* width: 60%; */
  margin: auto;
  justify-content: center;
  text-align: center;
  padding: 16px 0 25px 0;
}
.section-head-title {
  font-size: 30px;
  font-weight: 600;
  color: var(--darkText);
  margin-bottom: 13px;
}
.section-head-title span {
  color: var(--secondary);
}
.section-head-subtitle {
  font-size: 14px;
  font-weight: 400;
  color: var(--darkText);
}

.inpage-section-align {
  padding-top: 15px;
  margin-bottom: 40px;
}
.inpage-section-head {
  text-align: center;
  padding: 23px 0 10px 0;
  margin: auto;
}

.services-section-align {
  padding-top: 25px;
  padding-bottom: 40px;
}

.nodata-title {
  color: var(--lightGray);
}

.invalid-feedback{
  text-align: left;
}
.select-error-align{
  text-align: left;
}

/* Form start */
.logform {
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
}
.logform input {
  width: 100%;
  height: 100%;
  color: var(--blackColor);
  padding-top: 33px;
  border: none;
  outline: none;
  font-size: 16px;
}
.logform label {
  font-size: 16px;
  color: var(--textGray);
  font-weight: normal;
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0px;
  left: 0%;
  pointer-events: none;
  border-bottom: 1px solid var(--borderColor);
  margin-bottom: 0;
}
.logform label::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-bottom: 2px solid var(--primary);
  bottom: 0px;
  left: 0px;
  transform: translateX(-100%);
  transition: all 0.3s ease;
}
.content-name {
  position: absolute;
  /* bottom:5px; */
  left: 0px;
  transition: all 0.3s ease;
}
.logform input:focus + .label-name .content-name,
.logform input:valid + .label-name .content-name {
  transform: translateY(-100%);
  font-size: 14px;
  color: var(--primary);
}
.logform input:focus + .label-name::after,
.logform input:valid + .label-name::after {
  transform: translateX(0%);
}
/* Form End */

/* payment confirmation head start */

/* confirm alert start */
.overlay-custom-class-name {
  background: rgba(0, 0, 0, 0.7) !important;
  z-index: 9999 !important;
}

.react-confirm-alert h1 {
  font-size: 22px;
  color: var(--darkText);
  font-weight: 500;
}
.react-confirm-alert {
  font-size: 14px;
  color: var(--darkText);
  font-weight: 500;
  background: var(--whiteColor);
  border-radius: 10px;
  -webkit-box-shadow: 0px 1px 5px 2px rgb(255 255 255 / 18%);
  -moz-box-shadow: 0px 1px 5px 2px rgb(255 255 255 / 18%);
  box-shadow: 0px 1px 5px 2px rgb(255 255 255 / 18%);
}
.react-confirm-alert-button-group {
  justify-content: center !important;
  border-top: 1px solid var(--borderColor);
  padding-top: 20px;
}
.react-confirm-alert-button-group > button {
  background: var(--primary) !important;
  padding: 6px 34px !important;
  font-size: 14px !important;
}
/* confirm alert end */

.payment-head-outer {
  display: flex;
}
.payment-head {
  padding: 15px 0 18px 0;
}
.payment-head-logo {
  color: var(--whiteColor);
  font-size: 21px;
  font-weight: 600;
  width: 160px;
}
.go-back {
  margin-bottom: 13px;
  font-size: 15px;
  margin-left: 0px;
  font-weight: 500;
  padding-left: 5px !important;
}
.go-back a {
  color: var(--darkText);
  cursor: pointer;
}
.go-back a:hover {
  color: var(--darkText);
}
.go-back i {
  margin-right: 8px;
}
.payment-head-title {
  font-size: 30px;
  font-weight: 600;
  color: var(--darkText);
  margin-bottom: 13px;
  margin: auto;
  margin-top: 0;
}
/* payment confirmation head end */

/* shopping product title text limit */
.shopping-cart-product-name {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.promo-product-details h3 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  /* height: 35px; */
  /* line-height: 1.2; */
}
/* shopping product title text limit */
.dropdown-text-limit {
  display: inline;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: break-word;
  overflow: hidden;
}
.input-text-limite {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* back to top start */
#toTopBtn {
  position: fixed;
  bottom: 26px;
  right: 39px;
  z-index: 98;
  padding: 15px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  text-align: center;
  background-color: var(--primary);
  display: none;
  -webkit-box-shadow: 0px 2px 6px 0px rgb(0 0 0 / 40%);
  -moz-box-shadow: 0px 2px 6px 0px rgb(0 0 0 / 40%);
  box-shadow: 0px 2px 6px 0px rgb(0 0 0 / 40%);
}

#toTopBtn i {
  color: var(--whiteColor);
  font-size: 20px;
}
/* back to top end */

/* New fixed right button start */
.fixed-right-button {
  position: fixed;
  top: 18%;
  right: 0px;
  transform: translateY(-50%);
  z-index: 9;
}

/* .fixed-right-button :hover{
  box-shadow: 0 0 10px 0 #00d7c3 inset, 0 0 20px 2px #00d7c3;
  border: 3px solid #00d7c3;
} */

.fixed-right-button ul {
  margin: 0;
  padding: 0;
}
.fixed-right-button ul li {
  list-style: none;
  margin: 5px 0;
}
/* .fixed-right-button ul li:hover{
  border-radius: 50px 0px 0px 50px;
  -webkit-box-shadow: -2px 4px 3px 0px rgb(0 0 0 / 25%);
  -moz-box-shadow: -2px 4px 3px 0px rgb(0 0 0 / 25%);
  box-shadow: -2px 4px 3px 0px rgb(0 0 0 / 25%);
} */

.fixed-right-button ul li a {
  width: 57px;
  line-height: 50px;
  display: block;
  text-align: center;
  position: relative;
}
.fixed-right-button ul li a:hover {
  /* border-radius: 50px 0px 0px 50px; */
  -webkit-box-shadow: -2px 4px 3px 0px rgb(0 0 0 / 25%);
  -moz-box-shadow: -2px 4px 3px 0px rgb(0 0 0 / 25%);
  box-shadow: -2px 4px 3px 0px rgb(0 0 0 / 25%);
}

.fixed-right-button .fixed-prime-icon {
  position: absolute;
  width: 58px;
  line-height: 50px;
  right: 0px;
  border-radius: 7px;

  -webkit-box-shadow: -2px 4px 3px 0px rgb(0 0 0 / 25%);
  -moz-box-shadow: -2px 4px 3px 0px rgb(0 0 0 / 25%);
  box-shadow: -2px 4px 3px 0px rgb(0 0 0 / 25%);

  background: #ca3060;
  background: -moz-linear-gradient(left, #ca3060 0%, #ca3060 46%, #f4539e 100%);
  background: -webkit-linear-gradient(
    left,
    #ca3060 0%,
    #ca3060 46%,
    #f4539e 100%
  );
  background: linear-gradient(to right, #ca3060 0%, #ca3060 46%, #f4539e 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ca3060', endColorstr='#f4539e',GradientType=1 );
}
/* .fixed-right-button .fixed-prime-icon:hover{
  border-radius: 0% 50% 50% 0%;
  box-shadow: none;
} */
.fixed-right-button svg {
  font-size: 50px;
  padding: 7px;
  display: block;
  text-align: center;
  -webkit-animation: flip 1.6s linear infinite;
  animation: flip 1.6s linear infinite;
  color: #d5ad6d;
  background: -webkit-linear-gradient(transparent, transparent),
    -webkit-linear-gradient(top, rgba(213, 173, 109, 1) 0%, rgba(
            213,
            173,
            109,
            1
          )
          26%, rgba(226, 186, 120, 1) 35%, rgba(163, 126, 67, 1) 45%, rgba(
            145,
            112,
            59,
            1
          )
          61%, rgba(213, 173, 109, 1) 100%);
  background: -o-linear-gradient(transparent, transparent);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.fixed-right-button .svg-overlap {
  position: absolute;
}

.fixed-right-button img {
  width: 60px;
  padding: 14px;
  display: block;
  text-align: center;
  -webkit-animation: flip 1.6s linear infinite;
  animation: flip 1.6s linear infinite;
}

.fixed-right-button ul li a .prime-text {
  position: absolute;
  top: 0;
  /* right: -120px; */
  /* width: 182px; */
  white-space: nowrap;
  color: var(--whiteColor);
  /* background: var(--primary); */
  font-size: 15px;
  opacity: 0;
  backface-visibility: hidden;
  transition: 0.5s;
  border-radius: 50px 0px 0px 50px;
  background: #f4539e;
  background: -moz-linear-gradient(left, #f4539e 0%, #ca3060 46%, #ca3060 100%);
  background: -webkit-linear-gradient(
    left,
    #f4539e 0%,
    #ca3060 46%,
    #ca3060 100%
  );
  background: linear-gradient(to right, #f4539e 0%, #ca3060 46%, #ca3060 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f4539e', endColorstr='#ca3060',GradientType=1 );
}
.fixed-right-button ul li:hover .prime-text {
  opacity: 1;
  backface-visibility: visible;
  right: 48px;
  padding-left: 15px;
  padding-right: 5px;

  -webkit-box-shadow: -2px 4px 3px 0px rgb(0 0 0 / 25%);
  -moz-box-shadow: -2px 4px 3px 0px rgb(0 0 0 / 25%);
  box-shadow: -2px 4px 3px 0px rgb(0 0 0 / 25%);
}

@-webkit-keyframes flip {
  50% {
    transform: rotate3d(0, 1, 0, 90deg);
  }
}

@keyframes flip {
  50% {
    transform: rotate3d(0, 1, 0, 90deg);
  }
}
/* New fixed right button end */

/* loading bar styles start */
.services-page-loader .service-loader-outer {
  /* width:100%; */

  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin: auto;
  vertical-align: middle;
  text-align: center;
}
.services-page-loader .service-loader {
  margin-top: 45%;
  margin-left: 45%;
  position: absolute;
  left: 0;
  right: 0;
}


.brows-plan-loader .brows-plan-loader-outer{
  width: 90px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin: auto;
  vertical-align: middle;
  text-align: center;
}

.brows-plan-loader .service-loader{
  position: absolute;
  margin-top: 22%;
  margin-left: 45%;
  left: 0;
  right: 0;
}
/* .services-page-loader .service-loader-outer .three-dots-loading{
  justify-content: center !important;
} */

/* loading bar styles end */

/* product sold badge start */
.sold-badge-overlay-lft {
  position: absolute;
  left: 0%;
  top: 0px;
  width: 100%;
  /* height: 100%; */
  height: 60%;
  overflow: hidden;
  pointer-events: none;
  z-index: 100;
  background: rgb(255, 255, 255, 0.7);
  -webkit-transition: width 1s ease, height 1s ease;
  -moz-transition: width 1s ease, height 1s ease;
  -o-transition: width 1s ease, height 1s ease;
  transition: width 0.4s ease, height 0.4s ease;
}
.sold-badge-lft {
  margin: 0;
  padding: 0;
  color: var(--whiteColor);
  background: var(--primary);
  padding: 10px 10px;
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  line-height: normal;
  text-transform: uppercase;
}
.sold-badge-lft::before,
.sold-badge-lft::after {
  content: "";
  position: absolute;
  top: 0;
  margin: 0 0px;
  width: 100%;
  height: 100%;
  min-width: 100%;
}
.sold-badge::before {
  right: 100%;
}
.sold-badge::after {
  left: 100%;
}

.sold-badge-top-left {
  position: absolute;
  top: -19px;
  left: 0;
  right: 0;
  text-align: center;
  -ms-transform: translateX(-55%) translateY(-92%) rotate(-45deg);
  -webkit-transform: translateX(-55%) translateY(-92%) rotate(-45deg);
  transform: translateX(-55%) translateY(-92%) rotate(-45deg);
  -ms-transform-origin: top right;
  -webkit-transform-origin: top right;
  transform-origin: top right;
}
/* product sold badge end */

/* Digigold React overwrite styles start  */

/* -- digigold section align start -- */
.digi-gold-section-wrapper {
  margin-top: 35px;
  margin-bottom: 50px;
}
/* -- digigold section align start -- */

/* -- digi gold home page start-- */
.ant-input-group .ant-input-group-addon {
  border: 1px solid var(--lightGray);
}

/* -- digi gold home page end-- */

/* -- digi gold sign in page start-- */
.ant-modal-root .ant-modal-mask {
  z-index: 1050;
}
.ant-modal-root .ant-modal-wrap {
  z-index: 1050;
}


/* .ant-input-group {
  min-height: 45px;
} */
.ant-input-affix-wrapper {
  min-height: 45px;
  border-color: var(--lightGray);
}
.ant-input-affix-wrapper-focused {
  border: 1px solid var(--primary);
  box-shadow: 0 0 0 2px rgb(202 48 96 / 10%);
  outline: 0;
}
.ant-input-affix-wrapper:hover {
  border: 1px solid var(--primary) !important;
}
.ant-input-group-wrapper-disabled .ant-input-group-addon {
  color: var(--darkText);
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 45px;
  border-color: var(--lightGray);
}
.ant-select-single.ant-select-lg {
  text-align: left;
}
.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagignation-size-changer):hover
  .ant-select-selector {
  border-color: var(--primary) !important;
  box-shadow: 0 0 0 2px rgb(202 48 96 / 10%);
  outline: 0;
}
.ant-select-focused.ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
.ant-select:hover.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border-color: var(--primary) !important;
}
.ant-select-focused .ant-select-selector {
  border-color: var(--primary) !important;
}
.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagignation-size-changer)
  .ant-select-selector {
  border-color: var(--lightGray) !important;
  box-shadow: none !important;
}
.ant-select-selection-placeholder {
  color: var(--lightGray) !important;
}

/* .ant-select-single.ant-select-lg.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-lg.ant-select-show-arrow .ant-select-selection-placeholder{
  color: var(--lightGray);
}
.ant-select-single.ant-select-lg.ant-select-open .ant-select-selection-item{
  color: var(--darkText);
} */

.ant-picker-input input::placeholder {
  color: var(--lightGray) !important;
}


.ant-checkbox .ant-checkbox-inner {
  border-color: var(--lightGray);
}
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
  .ant-checkbox-checked:not(.ant-checkbox-disabled)
  .ant-checkbox-inner {
  background-color: var(--primary);
  border-color: var(--primary);
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--primary);
  border-color: var(--primary);
}
.ant-checkbox-checked .ant-checkbox-inner:hover {
  background-color: var(--primary);
  border-color: var(--primary);
}
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
  .ant-checkbox-inner,
.ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
  border-color: var(--primary);
}
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
  .ant-checkbox-checked:not(.ant-checkbox-disabled):after {
  border-color: var(--primary);
}

/* -- digi gold sign in page end-- */
/* Digigold React overwrite styles start  */

@media only screen and (min-width: 320px) and (max-width: 413px) {

  
  .productcard-arrow .react-multiple-carousel__arrow--left {
    margin-top: -46px;
  }
  .productcard-arrow .react-multiple-carousel__arrow--right {
    margin-top: -46px;
  }

  .payment-head-outer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .payment-head {
    padding: 0px 0 18px 0;
    /* display: none; */
  }
  .payment-head-title {
    font-size: 16px;
    margin: 0;
    margin-top: 0;
    margin-bottom: 20px;
    /* text-align: right; */
    padding-right: 5px;
  }
  .go-back {
    font-size: 13px;
  }
  .dropdown-text-limit {
    display: inline-block;
    max-width: 232px;
  }

  .fixed-right-button {
    top: 48%;
  }
  .fixed-right-button .fixed-prime-icon {
    width: 45px;
  }
  .fixed-right-button img {
    width: 50px;
    padding: 13px;
  }
  .fixed-right-button ul li a {
    width: 43px;
    font-size: 18px;
    line-height: 42px;
  }
  .fixed-right-button ul li:hover .prime-text {
    opacity: 1;
    backface-visibility: visible;
    right: 40px;
    padding-left: 10px;
    padding-right: 5px;
  }

  .top-banner-outer .react-multi-carousel-dot-list {
    display: none;
  }

  .tab-content .react-multiple-carousel__arrow--right {
    right: calc(0% + 1px);
  }
  .tab-content .react-multiple-carousel__arrow--left {
    left: calc(0% + 1px);
  }
  .navbar-res {
    display: none !important;
  }

  .nav-search .form-control {
    width: 233px;
  }

  .breadcrumb-outer {
    top: -7px;
    z-index: 9;
  }
  .breadcrumb {
    padding: 0.75rem 2rem !important;
    background-color: var(--secondary) !important;
  }

  .inpage-section-align {
    padding-top: 30px;
    margin-bottom: 50px;
  }
  .section-align {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .section-head {
    padding: 16px 0 24px 0;
    width: 100%;
  }
  .section-head-title {
    font-size: 20px;
    line-height: 33px;
  }
  .section-head-subtitle {
    font-size: 12px;
  }

  .services-section-align {
    padding-top: 26px;
    padding-bottom: 40px;
  }

  .digi-gold-section-wrapper {
    margin-top: 30px;
    margin-bottom: 20px;
  }

  #toTopBtn {
    width: 40px;
    height: 40px;
    bottom: 20px;
    right: 14px;
    padding: 11px;
  }
  #toTopBtn i {
    color: var(--whiteColor);
    font-size: 15px;
  }

  .sold-badge-top-left {
    top: -25px;
    left: 4px;
    transform: translateX(-51%) translateY(-90%) rotate(-41deg);
  }
}

@media only screen and (min-width: 414px) and (max-width: 540px) {
  .productcard-arrow .react-multiple-carousel__arrow--left {
    margin-top: -64px;
  }
  .productcard-arrow .react-multiple-carousel__arrow--right {
    margin-top: -64px;
  }
  .dropdown-text-limit {
    display: inline-block;
    max-width: 298px;
  }

  .payment-head-outer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .payment-head {
    padding: 0px 0 18px 0;
    /* display: none; */
  }
  .go-back {
    font-size: 13px;
  }
  .payment-head-title {
    font-size: 16px;
    margin: 0;
    margin-top: 0;
    padding-right: 5px;
  }

  .fixed-right-button {
    top: 36%;
  }
  .fixed-right-button .fixed-prime-icon {
    width: 45px;
  }
  .fixed-right-button img {
    width: 50px;
    padding: 13px;
  }
  .fixed-right-button ul li a {
    width: 43px;
    font-size: 18px;
    line-height: 42px;
  }
  .fixed-right-button ul li:hover .prime-text {
    opacity: 1;
    backface-visibility: visible;
    right: 40px;
    padding-left: 10px;
    padding-right: 0px;
  }

  .top-banner-outer .react-multi-carousel-dot-list {
    display: none;
  }

  .tab-content .react-multiple-carousel__arrow--right {
    right: calc(0% + 1px);
  }
  .tab-content .react-multiple-carousel__arrow--left {
    left: calc(0% + 1px);
  }
  .navbar-res {
    display: none !important;
  }
  .nav-search .form-control {
    width: 233px;
  }

  .breadcrumb-outer {
    top: -7px;
    z-index: 9;
  }
  .breadcrumb {
    padding: 0.75rem 2rem !important;
    background-color: var(--secondary) !important;
  }

  .section-align {
    margin-top: 30px;
    margin-bottom: 50px;
  }
  .inpage-section-align {
    padding-top: 30px;
    margin-bottom: 30px;
  }
  .section-head {
    padding: 16px 0 24px 0;
    width: 100%;
  }
  .section-head-title {
    font-size: 18px;
    line-height: 33px;
  }
  .section-head-subtitle {
    font-size: 13px;
  }
  .services-section-align {
    padding-top: 22px;
    padding-bottom: 40px;
  }

  #toTopBtn {
    width: 40px;
    height: 40px;
    bottom: 20px;
    right: 14px;
    padding: 11px;
  }
  #toTopBtn i {
    color: var(--whiteColor);
    font-size: 15px;
  }
}

@media only screen and (min-width: 414px) and (max-width: 475px) {
  .sold-badge-top-left {
    top: -46px;
    left: 5px;
  }
}

@media only screen and (min-width: 475px) and (max-width: 480px) {
  .productcard-arrow .react-multiple-carousel__arrow--left {
    margin-top: -81px;
  }
  .productcard-arrow .react-multiple-carousel__arrow--right {
    margin-top: -81px;
  }
  .sold-badge-top-left {
    top: 0px;
    left: 5px;
  }
}

@media only screen and (min-width: 540px) and (max-width: 768px) {

  .payment-head-outer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .payment-head {
    padding: 0px 0 18px 0;
    /* display: none; */
  }
  .go-back {
    font-size: 14px;
  }
  .payment-head-title {
    font-size: 16px;
    margin: 0;
    margin-top: 0;
    padding-right: 5px;
  }

  .fixed-right-button {
    top: 25%;
  }
  .fixed-right-button .fixed-prime-icon {
    width: 45px;
  }
  .fixed-right-button img {
    width: 50px;
    padding: 13px;
  }
  .fixed-right-button ul li a {
    width: 43px;
    font-size: 18px;
    line-height: 42px;
  }
  .fixed-right-button ul li:hover .prime-text {
    opacity: 1;
    backface-visibility: visible;
    right: 40px;
    padding-left: 10px;
    padding-right: 5px;
  }

  .top-banner-outer .react-multi-carousel-dot-list {
    display: none;
  }

  .tab-content .react-multiple-carousel__arrow--right {
    right: calc(0% + 1px);
  }
  .tab-content .react-multiple-carousel__arrow--left {
    left: calc(0% + 1px);
  }
  .navbar-res {
    display: none !important;
  }
  .nav-search .form-control {
    width: 233px;
  }

  .breadcrumb-outer {
    top: -7px;
  }
  .breadcrumb {
    padding: 0.75rem 2rem !important;
    background-color: var(--secondary) !important;
  }

  .inpage-section-align {
    padding-top: 30px;
    margin-bottom: 50px;
  }
  .section-head {
    padding: 16px 0 24px 0;
    width: 100%;
  }
  .section-head-title {
    font-size: 22px;
    line-height: 33px;
  }
  .section-head-subtitle {
    font-size: 14px;
  }
  .services-section-align {
    padding-top: 22px;
    padding-bottom: 40px;
  }

  #toTopBtn {
    width: 40px;
    height: 40px;
    bottom: 20px;
    right: 14px;
    padding: 11px;
  }
  #toTopBtn i {
    color: var(--whiteColor);
    font-size: 15px;
  }
}

@media only screen and (min-width: 640px) and (max-width: 768px) {
  .fixed-right-button {
    top: 32%;
  }
  .fixed-right-button .fixed-prime-icon {
    width: 45px;
  }
  .fixed-right-button img {
    width: 50px;
    padding: 13px;
  }
  .fixed-right-button ul li a {
    width: 43px;
    font-size: 18px;
    line-height: 42px;
  }
  .fixed-right-button ul li:hover .prime-text {
    opacity: 1;
    backface-visibility: visible;
    right: 40px;
    padding-left: 10px;
    padding-right: 5px;
  }

  .tab-content .react-multiple-carousel__arrow--right {
    right: calc(8% + 1px);
  }
  .tab-content .react-multiple-carousel__arrow--left {
    left: calc(8% + 1px);
  }
  .breadcrumb-outer {
    top: 52px;
  }
  .breadcrumb {
    padding: 0.75rem 2rem !important;
    background-color: var(--secondary) !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .productcard-arrow .react-multiple-carousel__arrow--left {
    margin-top: -81px;
  }
  .productcard-arrow .react-multiple-carousel__arrow--right {
    margin-top: -81px;
  }

  .payment-head-title {
    font-size: 18px;
    margin: auto;
    margin-top: 0;
    padding-right: 5px;
  }

  .fixed-right-button {
    top: 12%;
  }
  .fixed-right-button .fixed-prime-icon {
    width: 45px;
  }
  .fixed-right-button img {
    width: 50px;
    padding: 13px;
  }
  .fixed-right-button ul li a {
    width: 43px;
    font-size: 18px;
    line-height: 42px;
  }
  .fixed-right-button ul li:hover .prime-text {
    opacity: 1;
    backface-visibility: visible;
    right: 40px;
    padding-left: 10px;
    padding-right: 5px;
  }

  .top-banner-outer .react-multi-carousel-dot-list {
    bottom: 0;
    top: 21% !important;
  }

  .tab-content .react-multiple-carousel__arrow--right {
    right: calc(-3% + 1px);
  }
  .tab-content .react-multiple-carousel__arrow--left {
    left: calc(-3% + 1px);
  }
  .navbar-res {
    display: none !important;
  }
  .nav-search .form-control {
    width: 233px;
  }

  .breadcrumb-outer {
    top: 62px;
  }

  .section-head-title {
    font-size: 22px;
  }
  .section-head-subtitle {
    font-size: 14px;
  }
  .services-section-align {
    padding-top: 25px;
    padding-bottom: 40px;
  }

  .payment-head {
    padding: 0px 0 25px 0;
  }

  .sold-badge-top-left {
    top: -37px;
  }
}

@media only screen and (min-width: 900px) and (max-width: 992px) {
  .tab-content .react-multiple-carousel__arrow--right {
    right: calc(-10% + 1px);
  }
  .tab-content .react-multiple-carousel__arrow--left {
    left: calc(-10% + 1px);
  }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .top-banner-outer .react-multi-carousel-dot-list {
    bottom: 0;
    top: 51% !important;
  }

  .tab-content .react-multiple-carousel__arrow--right {
    right: calc(0% + 1px);
  }
  .tab-content .react-multiple-carousel__arrow--left {
    left: calc(0% + 1px);
  }
  .breadcrumb-outer {
    top: 60px;
  }

  .section-head-title {
    font-size: 26px;
  }
  .section-head-subtitle {
    font-size: 14px;
  }

  .inpage-section-align {
    padding-top: 30px;
    margin-bottom: 30px;
  }

  .services-section-align {
    padding-top: 30px;
    padding-bottom: 40px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1280px) {
  .section-align {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  
  .productcard-arrow .react-multiple-carousel__arrow--left {
    margin-top: -92px;
  }
  .productcard-arrow .react-multiple-carousel__arrow--right {
    margin-top: -92px;
  }
  
  .top-banner-outer .react-multi-carousel-dot-list {
    bottom: 0;
    top: 51% !important;
  }

  .tab-content .react-multiple-carousel__arrow--right {
    right: calc(-3% + 1px);
  }
  .tab-content .react-multiple-carousel__arrow--left {
    left: calc(-3% + 1px);
  }
  .breadcrumb-outer {
    top: 60px;
  }

  .section-head-title {
    font-size: 26px;
  }
  .section-head-subtitle {
    font-size: 14px;
  }

  .inpage-section-align {
    padding-top: 30px;
    margin-bottom: 30px;
  }

  .sold-badge-top-left {
    top: -6px;
  }

  .dropdown-text-limit {
    display: inline-block;
    max-width: 298px;
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1440px) {
  .breadcrumb-outer {
    top: 76px;
  }

  .sold-badge-top-left {
    top: -19px;
  }
}

@media only screen and (min-width: 1366px) and (max-width: 1440px) {
  .top-banner-outer .react-multi-carousel-dot-list {
    bottom: 0;
    top: 45% !important;
  }
}
