.payment-app{
    background: url('../../../../public/images/home/mobile-app-bg.png');
    background-size: contain;
      background-position: 0 113%;
      background-repeat: no-repeat;
  }
  .payment-app-banner{
    margin-bottom: 5%;
  }
  .banner-phone-image{
    padding-left: 6%;
  }
  .banner-phone-image img{
    width:100%;
  }
  .payment-app-banner-content{
    padding-top: 4%;
  }
  .payment-app-banner-title{
    color: #393186;
    font-size: 40px;
    font-weight: 500;
  }
  .payment-app-banner-p{
    color: #212121;
    font-size: 14px;
    font-weight: 500;
  }



  @media only screen and (min-width: 275px) and (max-width: 319px){
    .payment-app-banner-title {
      color: #393186;
      font-size: 16px;
      font-weight: 500;
    }
    .payment-app-banner-p {
      color: #212121;
      font-size: 12px;
      font-weight: 500;
    }
  
  }
  

  @media only screen and (min-width: 320px) and (max-width: 414px){
  
  .payment-app { 
    background-position: bottom; 
  }
  .payment-app-banner-content { 
    margin-bottom: 7%;
  }
  .payment-app-banner-content {
    padding-top: 0%;
  }
  .payment-app-banner-title { 
    font-size: 26px; 
    margin-bottom: 20px;
  }
  .payment-app-banner-p { 
    font-size: 12px; 
  }
  .banner-phone-image {
    padding-left: 0;
  }
  
  .footer-outer {
    padding: 7% 7% 1% 7%;
  }
  .footer-box:last-child {
    margin-bottom: 0;
  }
  .footer-box{
    display: inline-block;
    width: 100%;
  }
  .footer-box:first-child{
    display: inline-block;
    width: 100%;
  }
  .footer-box{
    margin-bottom: 20px;
    border-bottom: 1px solid #fff;
    padding-bottom: 20px;
    }
    
    .footer-bottom .policy{
      display: flex;
      float: none;
    }
    .footer-bottom .policy > div {
      padding: 0px 3px 0px 3px;
      margin-top: 19px;
    }
    .footer-bottom .policy > div:first-child {
      padding-left: 0px;
    }
  
  }
  
  @media only screen and (min-width: 414px) and (max-width: 767px){
      
    .payment-app { 
      background-position: bottom; 
    }
    .payment-app-banner-content { 
      margin-bottom: 7%;
    }
    .payment-app-banner-content {
      padding-top: 0%;
    }
    .payment-app-banner-title { 
      font-size: 28px; 
      margin-bottom: 20px;
    }
    .payment-app-banner-p { 
      font-size: 12px; 
    }
    .banner-phone-image {
      padding-left: 0;
    }
  
    .footer-outer {
      padding: 7% 7% 1% 7%;
    }
    .footer-box:last-child {
      margin-bottom: 0;
    }
    .footer-box{
      display: inline-block;
      width: 100%;
    }
    .footer-box:first-child{
      display: inline-block;
      width: 100%;
    }
    .footer-box{
      margin-bottom: 20px;
      border-bottom: 1px solid #fff;
      padding-bottom: 20px;
      }
      .footer-bottom .policy{
        display: flex;
        float: none;
      }
      .footer-bottom .policy > div {
        padding: 0px 8px 0px 8px;
        margin-top: 19px;
      }
      .footer-bottom .policy > div:first-child {
        padding-left: 0px;
      }
  }
  
  @media only screen and (min-width: 768px) and (max-width: 991px){
      
   .payment-app { 
    background-position: bottom; 
    }
    .payment-app-banner {
      margin-bottom: 5%;
    }
    .payment-app-banner-content {
      padding-top: 0%;
  }
    .payment-app-banner-content { 
      margin-bottom: 11%;
    }
    .payment-app-banner-title { 
      font-size: 24px; 
    }
    .payment-app-banner-p{
      font-size: 12px;
    }
    .banner-phone-image {
      padding-left: 6%;
      padding-top: 13%;
    }
  
    .footer-box:first-child {
      display: inline-block;
      width: 34%;
    }
    .footer-box {
      display: inline-block;
      width: 33%;
    }
    .footer-box{
      margin-bottom: 20px;
      border-bottom: none;
      padding-bottom: 20px;
      }
      .footer-bottom .policy{
        display: flex;
        float: none;
      }
      .footer-bottom .policy > div {
        padding: 0px 17px 0px 12px;
        margin-top: 19px;
      }
      .footer-bottom .policy > div:first-child {
        padding-left: 0px;
      }
  }
  
  
  @media only screen and (min-width: 992px) and (max-width: 1279px){
    
    .payment-app-banner-title { 
      font-size: 28px; 
    }
    .payment-app-banner {
      margin-bottom: 2%;
    }
    .payment-app-banner-content {
      padding-top: 2%;
  }
  
  }
  
  
  @media only screen and (min-width: 1280px) and (max-width: 1440px){
     
  }