.prime-member-banner {
  margin-top: 0px;
  margin-bottom: 50px;
}
.join-vips img{
    height: 245px;
  }
  .join-vips-content {
    position: absolute;
    top: 28%;
    left: 0;
    right: 0;
    text-align: center;
    transform: translate(0%, -21%);
    color: var(--whiteColor);
  }
  .join-vips-content h1{
    font-size: 42px;
    font-weight: 500;
    margin-bottom: 20px;
  }
  .join-vips-content .join-vips-content-p{
    font-size: 22px;
    font-weight: 500;
  }
  .join-vips-content a{
    border: 1px solid var(--whiteColor) !important;
    color: var(--whiteColor) !important;
    padding: 9px 29px;
  }


  @media only screen and (min-width: 275px) and (max-width: 319px){
    .join-vips-content h1 {
      font-size: 16px;
    }
    .join-vips-content {
      width: 86%;
      margin: auto;
    }
    .join-vips-content .join-vips-content-p {
      font-size: 12px;
      font-weight: 400;
    }

  
  }

  @media only screen and (min-width: 320px) and (max-width: 414px){
  
    .online-store-modal .titleMain {
      font-size: 16px;
      margin-left: 2px;
    }
    .online-store-modal-close {
        display: contents;
    }
    .online-stores-terms {
        padding: 0 2px;
        font-size: 12px;
    }
    .online-store-btn button {
        font-size: 15px;
    }
  
  
    .tab-panel-card .nav-tabs {
      border: none;
      flex-wrap: nowrap;
    }
    .tab-panel-card .nav-tabs>.nav-item>.nav-link {
      margin: 0 15px;
      font-size: 15px;
    }
  
   
    .join-vips img {
      height: 222px;
    }
      .join-vips-content { 
        top: 28%;
        left: 0%;
        width: 90%;
        margin: auto;
    }
      .join-vips-content h1 {
        font-size: 25px;
        font-weight: 500;
        margin-bottom: 20px;
    }
      .join-vips-content .join-vips-content-p {
        font-size: 12px;
        font-weight: 500;
    }
  
    
  
    .payment-app { 
      background-position: bottom; 
    }
    .payment-app-banner-content { 
      margin-bottom: 7%;
    }
    .payment-app-banner-content {
      padding-top: 0%;
    }
    .payment-app-banner-title { 
      font-size: 26px; 
      margin-bottom: 20px;
    }
    .payment-app-banner-p { 
      font-size: 12px; 
    }
    .banner-phone-image {
      padding-left: 0;
    }
  
  
  }
  
  @media only screen and (min-width: 414px) and (max-width: 767px){
    
    .online-store-modal .titleMain {
      font-size: 16px;
      margin-left: 2px;
    }
    .online-store-modal-close {
        display: contents;
    }
    .online-stores-terms {
        padding: 0 2px;
        font-size: 12px;
    }
    .online-store-btn button {
        font-size: 15px;
    }
  
  
    .tab-panel-card .nav-tabs>.nav-item>.nav-link {
      font-size: 15px;
    }
  
    
    .join-vips img {
      height: 222px;
    }
    .join-vips-content { 
      top: 28%;
      left: 0%;
      width: 90%;
      margin: auto;
    }
    .join-vips-content h1 {
      font-size: 25px;
      font-weight: 500;
      margin-bottom: 20px;
    }
    .join-vips-content .join-vips-content-p {
      font-size: 12px;
      font-weight: 500;
    }
    
    
  
    .payment-app { 
      background-position: bottom; 
    }
    .payment-app-banner-content { 
      margin-bottom: 7%;
    }
    .payment-app-banner-content {
      padding-top: 0%;
    }
    .payment-app-banner-title { 
      font-size: 28px; 
      margin-bottom: 20px;
    }
    .payment-app-banner-p { 
      font-size: 12px; 
    }
    .banner-phone-image {
      padding-left: 0;
    }
  
    
  }
  
  @media only screen and (min-width: 540px) and (max-width: 767px){
    .online-store-modal .modal-dialog {
      max-width: 430px !important;
      margin: 4.75rem auto;
    }
    .online-store-modal .titleMain {
        font-size: 16px;
    }
    .online-store-btn button {
        font-size: 15px;
    }
  }
  
  @media only screen and (min-width: 768px) and (max-width: 991px){
    .online-store-modal .modal-dialog {
      max-width: 531px !important;
    }
    .online-store-modal .titleMain {
        font-size: 18px;
    }
    
    
    .join-vips img {
      height: 210px;
    }
    .join-vips-content { 
      top: 28%;
      left: 0%; 
    }
    .join-vips-content h1 {
      font-size: 30px;
      font-weight: 500;
      margin-bottom: 20px;
    }
    .join-vips-content .join-vips-content-p {
      font-size: 16px;
      font-weight: 500;
    }
  
    
  
   .payment-app { 
    background-position: bottom; 
    }
    .payment-app-banner {
      margin-bottom: 5%;
    }
    .payment-app-banner-content {
      padding-top: 0%;
  }
    .payment-app-banner-content { 
      margin-bottom: 11%;
    }
    .payment-app-banner-title { 
      font-size: 24px; 
    }
    .payment-app-banner-p{
      font-size: 12px;
    }
    .banner-phone-image {
      padding-left: 6%;
      padding-top: 13%;
    }
  
    
  }
  
  
  @media only screen and (min-width: 992px) and (max-width: 1279px){
   
  
    .join-vips-content { 
      top: 28%;
      left: 0%;
    }
  
    .payment-app-banner-title { 
      font-size: 28px; 
    }
    .payment-app-banner {
      margin-bottom: 2%;
    }
    .payment-app-banner-content {
      padding-top: 2%;
  }
  
  }
  
  
  @media only screen and (min-width: 1280px) and (max-width: 1440px){
     
  }